@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0px;

  > button:nth-child(6) {
    margin-left: 40px;
  }

  .dash {
    margin: 10px 20px 0px 20px;
    width: 6px;
    height: 2px;
    background-color: $turquoise;
  }

  :global {
    .ant-picker {
      width: 110px !important;
    }
  }
}

.text {
  @include text;
  margin: 0px 25px 0px 15px;
  font-weight: 600;
  min-width: 100px;
}

@media screen and (max-width: 1150px) {
  .text {
    margin: 0px 15px;
  }

  .wrapper {
    > button:nth-child(6) {
      margin-left: 17px;
    }

    .text {
      min-width: 89px;
    }

    .breakText {
      min-width: 80px;
    }
  }
}

@media screen and (max-width: 1010px) {
  .wrapper {
    flex-wrap: wrap;
    max-width: 600px;
    margin: 0 auto;

    > div:nth-child(8) {
      margin-left: 163px;
    }
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    flex-wrap: wrap;
    max-width: initial;
    margin: 0 auto;
    border-bottom: 1px solid $border;
    padding: 20px 15px;
    margin: 0 -15px;

    > button:nth-child(1) {
      margin: 0 0 10px 0;
    }

    > p:nth-child(2) {
      width: 73%;
      margin-top: 7px;
    }

    > div:nth-child(3) {
      width: 45%;

      :global {
        .ant-picker {
          width: 100% !important;
        }
      }
    }

    > span:nth-child(4) {
      position: relative;
      width: 10%;
      margin: 0;
      margin-top: 10px;
      background-color: transparent;

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 2px;
        background: $turquoise;
      }
    }

    > div:nth-child(5) {
      width: 45%;

      :global {
        .ant-picker {
          width: 100% !important;
        }
      }
    }

    > button:nth-child(6) {
      margin: 15px 0 10px 0;
    }

    > p:nth-child(7) {
      width: 73%;
      margin-top: 7px;
    }

    > div:nth-child(8) {
      margin-left: 0;
      width: 45%;

      :global {
        .ant-picker {
          width: 100% !important;
        }
      }
    }

    > span:nth-child(9) {
      position: relative;
      width: 10%;
      margin: 0;
      margin-top: 10px;
      background-color: transparent;

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 2px;
        background: $turquoise;
      }
    }

    > div:nth-child(10) {
      width: 45%;

      :global {
        .ant-picker {
          width: 100% !important;
        }
      }
    }
  }
}
