@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.borderBlock {
  @include border($border, 8px);
  padding: 30px 60px 60px 60px;
  margin-top: 30px;
}

.subTitle {
  @include h2;
  display: flex;
  margin: 0 -60px 30px -60px;
  padding-left: 60px;
  padding-bottom: 30px;
  border-bottom: 1px solid $border;

  span {
    padding-right: 5px;
    color: $turquoise;
  }
}

.wrappTitle {
  margin: 0px -60px 30px -60px;
  padding: 0px 60px 30px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $border;

  b {
    @include h2;
    position: relative;

    span {
      color: $turquoise;
    }
  }

  :global {
    .ant-switch-checked {
      background: $turquoise;
    }
  }
}

@media screen and (max-width: 970px) {
  .borderBlock {
    padding: 30px 40px 40px 40px;
    overflow: hidden;
  }

  .subTitle {
    margin: 0 -40px 30px -40px;
  }

  .wrappTitle {
    margin: 0px -40px 30px -40px;
    padding: 0px 40px 30px 40px;
  }
}

@media screen and (max-width: 685px) {
  .borderBlock {
    padding: 15px 20px 30px 20px;
    overflow: hidden;
  }

  .subTitle {
    margin: 0 -20px 15px -20px;
  }

  .wrappTitle {
    margin: 0px -20px 15px -20px;
    padding: 0px 20px 10px 20px;
    flex-wrap: wrap;
  }
}
