.passwordWrapp {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 30px;
}

.wrapper {
  margin-bottom: 50px;
}

@media screen and (max-width: 970px) {
  .passwordWrapp {
    grid-template-columns: repeat(2, 1fr);

    > div:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  .passwordWrapp {
    grid-template-columns: repeat(1, 1fr);

    > div:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .passworsBtn {
    width: 100%;
  }
}
