@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.color {
  height: 25px;
  width: 3px;
  margin: 0px 15px;
}

.time {
  @include desc-1;
  color: $text-grey;
  margin-right: 30px;
  white-space: nowrap;
  margin-top: 3px;
}

.name {
  padding-right: 8px;
  padding-left: 8px;
}

.checkbox {
  align-items: center;

  :global {
    .ant-checkbox {
      margin-right: 5px;
    }

    .ant-checkbox-inner {
      @include icon(20px);

      &::after {
        height: 10px;
        top: 27%;
        left: 20%;
      }
    }
  }

  span {
    font-weight: 400;
  }
}

.oldPrice {
  color: $dark-grey;
  margin-right: 5px;

  &.border {
    span {
      width: fit-content;
      display: block;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: $dark-grey;
        top: 11px;
      }
    }
  }
}

.price {
  display: flex;
}

@media screen and (max-width: 767px) {
  .time {
    margin-top: 0;
  }
}
