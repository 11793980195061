@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.uploadButton {
  width: 270px;

  div {
    margin-top: 8px;
  }
}

.uploadWrap {
  margin: 0 auto;
  max-width: 280px;
  width: 100%;
  height: 280px;

  :global {
    .ant-upload.ant-upload-select {
      display: block;
    }

    .ant-upload.ant-upload-select-picture-card {
      border: 1px dashed $dark-grey;
      border-radius: 50%;
    }

    .ant-upload-list-item-info {
      border-radius: 50%;
    }

    .ant-upload-picture-card-wrapper {
      height: 100%;
    }

    .ant-upload-list {
      height: 100%;
    }

    .ant-upload-picture-card-wrapper .ant-upload {
      width: 100%;
      height: 100%;
    }

    .ant-upload-list-picture-card-container {
      height: 100%;
      width: 100%;
      border-radius: 50%;

      .ant-upload-list-item {
        padding: 0;
        border-color: transparent;
      }

      img {
        object-fit: cover;
      }
    }
  }
  * {
    transition: none !important;
  }
}
