@import 'assets/scss/colors.scss';

.switcher:global {
  &.ant-switch {
    background: $switch-color;

    &.ant-switch-checked {
      background: $turquoise;
    }
  }

  &.ant-switch-disabled {
    background: $light-grey;

    &.ant-switch-checked {
      background: lighten($turquoise, 20%);
    }
  }
}
