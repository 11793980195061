@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  display: flex;
}

.sidebar {
  max-width: 360px;
  width: 100%;
  min-height: calc(100vh - 76px);
  border-right: 1px solid $light-grey;
  padding: 30px 30px 30px 35px;

  .sidebarTitle {
    @include h2;
    color: $black;
    margin-bottom: 25px;
  }

  .search {
    margin-bottom: 30px;
  }
}

.avatarWrapp {
  @include icon(160px);
}

.mainContent {
  width: 100%;
  padding: 30px 136px;
  background-color: $bg-grey-2;
}

.header {
  @include center-between();
  margin-bottom: 40px;

  .headerAvatar {
    display: flex;
    align-items: center;

    :global {
      .ant-avatar {
        display: block;
        background-color: $light-grey;

        img {
          object-fit: cover;
        }
      }
    }
  }

  .headerInfo {
    margin-left: 20px;
  }

  .headerName {
    @include h1;
    @include text-nowrap();
    max-width: 30vw;
    color: $black;
  }

  .headerDescription {
    @include title;
    color: $black;
    margin-bottom: 10px;
  }

  .headerPhone {
    @include text;
    color: $black;
  }

  .headerBtn {
    > button:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1350px) {
  .wrapper {
    .sidebar {
      padding: 30px 20px 30px 20px;
      max-width: 240px;
    }

    .mainContent {
      padding: 30px 20px;
    }

    .headerBtn {
      button {
        p {
          white-space: nowrap;
        }
      }
    }
  }
}

@media screen and (max-width: 970px) {
  .header {
    align-items: flex-start;
    flex-direction: column-reverse;

    .headerBtn {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 1024px) {
  .avatarWrapp {
    @include icon(120px);

    &::before {
      width: 40px;
      height: 30px;
      margin-left: -16px;
      margin-top: -15px;
    }
  }

  .headerName {
    max-width: 50vw;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    position: relative;

    .sidebar {
      max-width: 100%;
      width: 100%;
    }

    .hideScroll {
      max-height: 100vh;
      overflow: hidden;
      display: none;
    }

    .mainContent {
      display: none;
    }

    .mainContent.active {
      display: block;
      position: fixed;
      top: 75px;
      bottom: 0;
      right: 0;
      z-index: 15;
      height: calc(100vh - 75px);
      overflow-y: auto;
    }
  }

  .header {
    .headerInfo {
      max-width: 100%;
    }

    .headerName {
      max-width: 70vw;
    }
  }
}
.mobileBtn {
  display: none;
}

@media screen and (max-width: 550px) {
  .wrapper {
    .mainContent {
      padding: 24px 15px;
    }
  }

  .header {
    .headerAvatar {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      span {
        margin: 0 auto;
      }

      .headerInfo {
        margin-left: 0;
        margin-top: 15px;
      }
    }

    .headerName {
      max-width: 86vw;
    }
  }

  .mobileBtn {
    display: block;
    margin-bottom: 23px;

    button {
      padding-left: 0;
    }
  }
}
