@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.contentWrapp {
  .info {
    @include text;
    margin-bottom: 15px;
  }

  .warning {
    @include h2;
    color: $error-color;
    margin-bottom: 15px;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    p {
      @include text;
      margin-bottom: 15px;

      svg {
        margin-right: 5px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .contentWrapp {
    .list {
      grid-template-columns: repeat(1, 1fr);

      > p {
        width: 100%;
      }

      > p:last-child {
        margin-bottom: 0;
      }
    }
  }
}
