@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  padding-bottom: 30px;

  .title {
    @include text;
    margin-bottom: 10px;

    > span {
      margin-left: 6px;
    }
  }
}

.accordionItemWrapp {
  margin-bottom: 15px;
}

@media screen and (max-width: 500px) {
  .title {
    :global {
      .ant-tooltip-open {
        .ant-tooltip {
          width: 230px;
          max-width: 230px;
        }
      }
    }
  }
}
