@import 'assets/scss/colors.scss';

.editPages {
  .titleWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 60px;

    h1 {
      margin: 0 auto;
    }

    .arrow {
      width: 30px;
      height: 16px;
      cursor: pointer;

      path {
        fill: $turquoise;
      }
    }
  }
}
