@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.firstModal {
  max-width: 800px;
}

.aboutText {
  textarea {
    min-height: 110px;
  }
}

.title {
  @include h1;
  margin-bottom: 19px;
  color: $black;
}

.logOut {
  color: $dark-grey;
  cursor: pointer;
}

.description {
  @include text;
  color: $black;
}

.borderBlock {
  @include border($border, 8px);
  padding: 30px 60px 60px 60px;
  margin-top: 30px;
}

.mainInfoWrapper {
  display: flex;
}

.subTitle {
  @include h2;
  display: flex;
  margin: 0 -60px 30px -60px;
  padding-left: 60px;
  padding-bottom: 30px;

  span {
    padding-right: 5px;
    color: $turquoise;
  }

  border-bottom: 1px solid $border;
}

.wrappTitle {
  margin: 0px -60px 30px -60px;
  padding: 0px 60px 30px 60px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $border;

  b {
    @include h2;
  }

  span {
    color: $turquoise;
  }

  :global {
    .ant-switch-checked {
      background: $turquoise;
    }
  }
}

.mainInfoWrapper {
  > div:first-child {
    width: 47%;
  }

  > div:last-child {
    width: 48%;
    margin-left: auto;
  }
}

.wrapperGeneralInfo {
  > div {
    margin-bottom: 30px;
  }
}

.phoneTitle {
  @include text;
  display: block;
  margin-bottom: 10px;
  .necessaryLabel {
    @include text;
    color: $dark-grey;
  }
}

.item {
  font-size: 22px;
  margin: 40px 0px;
}

.switch {
  margin: 10px;
}

.uploadWrapper {
  display: flex;
  align-items: flex-end;
  margin: 15px 0px;
}

.uploadLogo {
  @include text;
  text-align: right;
  color: $black;
  margin-bottom: 25px;
}

.logo {
  color: $dark-grey;
  font-size: 18px;
  margin-top: 30px;
}

.button {
  margin-top: 25px;
}

.generalInfo {
  color: $dark-grey;
  font-size: 18px;
  margin: 0px 0px 20px;
  width: 100%;
}

.email {
  margin: 20px 0px;
  color: $text-grey;
}

.save {
  margin-top: 45px;
}

.items {
  div {
    font-size: 12px;
  }
}

@media screen and (max-width: 1350px) {
  .wrapper {
    margin: 30px 20px 0px 20px;
    padding-top: 0;
    width: auto;
  }
}

@media screen and (max-width: 1024px) {
  .uploadLogo {
    text-align: left;
  }

  .aboutText {
    textarea {
      min-height: 154px;
    }
  }
}

@media screen and (max-width: 685px) {
  .wrapper {
    margin: 20px 5px 0px 5px;
  }

  .mainInfoWrapper {
    flex-direction: column;

    > div {
      width: 100%;

      &:first-child {
        width: 100%;
      }

      &:last-child {
        width: 100%;
        margin-left: initial;
      }
    }

    .uploadAvatar {
      max-width: 160px;
      height: 160px;

      :global {
        .ant-upload {
          svg {
            @include icon(40px);
          }
        }
      }
    }
  }
  .wrapper .info {
    flex-direction: column;
  }

  .save {
    width: 100%;
  }

  .description {
    padding: 0 10px;
  }

  .passwordWrapp {
    grid-template-columns: repeat(1, 100%);
    grid-column-gap: 0px;
  }
}
