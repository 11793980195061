@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  font-size: 26px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .headerMobile {
    @include center;
    padding-bottom: 20px;
    position: relative;

    div {
      position: absolute;
      left: 0;
    }

    p {
      @include h1;
      @include text-nowrap();
      max-width: 65%;
    }
  }
}
