@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.card {
  @include border($border, 6px);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: $white;
  max-width: 335px;
  width: 100%;

  p {
    @include desc-1;
    @include text-nowrap;
    color: $black;
    margin-bottom: 0;
  }

  .checkIcon {
    @include icon(20px);
    margin-bottom: 2px;
  }

  .downloadIcon,
  .trashIcon {
    @include icon(20px);
    margin-top: 2px;
    margin-bottom: 2px;
    cursor: pointer;
  }

  .downloadIcon {
    display: none;
  }

  .trashIcon {
    display: none;
    margin-left: auto;
  }

  &:hover {
    .checkIcon {
      display: none;
    }

    .downloadIcon,
    .trashIcon {
      display: block;
    }
  }
}
