@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.checkbox {
  span {
    @include text;
    font-weight: 600;
  }
  :global {
    .ant-checkbox {
      @include icon(23px);
      margin-right: 15px;
    }

    .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
      background-color: $turquoise;
      border: none !important;
      border-radius: 6px;
    }

    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: $border;
    }

    .ant-checkbox .ant-checkbox-inner {
      @include icon(26px);
      @include border($input-border, 6px);
      border: 1px solid $input-border !important;

      &:after {
        content: '';
        background-image: url('../../assets/images//common/redesign/ok.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 21px;
        height: 11px;
        transform: initial;
        border: none;
        top: 29%;
        left: 22.5%;
      }
    }

    .ant-checkbox-checked::after {
      border: none;
    }
  }
}
