$aligns: right, left, center;
$weights: 300, 400, 500, 600, 700, 800;

.pointer {
  cursor: pointer;
}

@each $align in $aligns {
  .text-#{$align} {
    text-align: align;
  }
}

@each $weight in $weights {
  .fw-#{$weight} {
    font-weight: $weight;
  }
}

@for $i from 0 through 10 {
  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }

  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }

  .m-#{$i} {
    margin: #{$i}px !important;
  }
}

.text-black {
  color: $black !important;
}

.text-error {
  color: $error-color !important;
}

.text-success {
  color: $green !important;
}

.text-info {
  color: $blue !important;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.w-fit-content {
  width: fit-content;
}

.seo-block {
  margin: 60px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;

  @media screen and (max-width: 768px) {
    margin: 30px 0;
    gap: 20px;
    grid-template-columns: 1fr;
  }
}

.d-none {
  display: none;
}
