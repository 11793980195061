@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.not-found-page {
  @include center;
  min-height: 100vh;

  .not-found-page_wrapper {
    display: flex;
    align-items: center;
    gap: 60px;

    img {
      width: 385px;
      height: 309px;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
    }

    .not-found-page_desc {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 10px;

      .not-found-page_title-wrapper {
        display: flex;
        gap: 15px;

        h2 {
          margin-bottom: 0;
        }
      }

      p {
        @include title;
        margin-bottom: 0;
      }

      button p {
        @include text;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .not-found-page_wrapper {
      gap: 40px;

      img {
        width: 320px;
        height: 257px;
      }

      .not-found-page_desc {
        .not-found-page_title-wrapper {
          flex-direction: column;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 40px;
    margin-bottom: 100px;

    .not-found-page_wrapper {
      flex-direction: column-reverse;
      gap: 60px;
      align-items: center;
      justify-content: center;

      .not-found-page_desc {
        align-items: center;
        justify-content: center;

        .not-found-page_title-wrapper {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    max-width: 290px;
    margin: 20px auto 40px;
    min-height: auto;

    .not-found-page_wrapper {
      gap: 40px;

      img {
        width: 290px;
        height: 233px;
      }

      .not-found-page_desc {
        align-items: flex-start;
        justify-content: flex-start;

        .not-found-page_title-wrapper {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}
