@import 'assets/scss/mixins.scss';

.row {
  display: flex;
  width: 100%;
  gap: 20px;

  > p {
    margin-bottom: 0;
    margin-top: 38px;
  }

  .input,
  .textarea {
    max-width: 350px;
    width: 100%;
  }

  button.remove {
    display: flex;
    align-items: center;
    margin-top: 30px;
    max-height: 60px;
    padding: 10px;
    margin-left: -10px;
    gap: 5px;

    svg {
      @include icon(20px);
    }
  }
}
