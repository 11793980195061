@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  max-width: 1260px;
  margin: 0 auto;
  margin-top: 30px;
  padding: 0 30px;
  padding-bottom: 30px;

  h1 {
    display: flex;
    align-items: center;

    svg {
      margin-left: 7px;
    }
  }
}

.info {
  margin-bottom: 30px;

  p {
    @include text;
  }
}

.btn {
  margin-top: 30px;
}

@media screen and (max-width: 1100px) {
  .wrapper {
    padding: 0 20px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    padding: 0 5px;
    padding-bottom: 30px;
  }

  .contentWrapp {
    padding: 0 10px;
  }

  .btn {
    width: 100%;
  }
}

.documents-list {
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 15px));
  gap: 20px;
  margin-top: 30px;
}

@media screen and (max-width: 1200px) {
  .documents-list {
    grid-template-columns: repeat(3, calc(33.3333% - 13.33333px));
  }
}

@media screen and (max-width: 900px) {
  .documents-list {
    grid-template-columns: repeat(2, calc(50% - 10px));
  }
}

@media screen and (max-width: 767px) {
  .documents-list {
    grid-template-columns: repeat(1, 100%);
  }
}
