@import 'assets/scss/colors.scss';

.second {
  margin-bottom: 60px;

  h2 {
    margin-bottom: 20px;
  }

  p {
    color: $grey;
    margin-bottom: 0;
  }
}
