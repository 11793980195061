@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography-mixins.scss';

.wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0px;

  .switch {
    margin-top: 5px;
  }

  .text {
    margin: 5px 30px 0px 15px;
    @include text;
    color: $black;
  }

  > button {
    margin-left: 30px;
    margin-top: 10px;
  }

  :global {
    .ant-picker {
      width: 110px;
    }
  }
}

.title {
  display: flex;
  align-items: center;

  .titleName {
    @include text-nowrap();
    display: block;
    max-width: 280px;
  }
}

.time {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.dash {
  margin: 10px 20px 0px 20px;
  width: 6px;
  height: 2px;
  background-color: $turquoise;
}

.line {
  height: 1px;
  background-color: $border;
  margin: 30px -60px;
}

.btn {
  @include center-between;
}

@media screen and (max-width: 850px) {
  .line {
    margin: 30px -30px;
  }
}

@media screen and (max-width: 767px) {
  .title {
    width: 100%;
    text-align: center;
    display: initial;

    .titleName {
      width: 95%;
      margin: auto;
      max-width: fit-content;
    }
  }

  .wrapper {
    flex-wrap: wrap;
    position: relative;

    > div:nth-child(1) {
      order: 1;
    }

    > div:nth-child(2) {
      order: 2;
      margin-right: 0;
    }

    > div:nth-child(3) {
      order: 4;
      width: 50%;
      margin-top: 10px;
    }

    > div:nth-child(5) {
      order: 5;
      width: 50%;
      margin-top: 10px;

      > div {
        margin-left: auto;
      }
    }

    > button:nth-child(6) {
      order: 3;
      margin-left: 0;
      width: 100%;
      margin-top: -22px;

      > svg {
        margin-left: auto;
      }
    }

    .dash {
      position: absolute;
      left: 49%;
      top: 74%;
      transform: translateY(-50%);
      margin: 0;
    }

    :global {
      .ant-picker {
        width: 80%;
      }
    }
  }
  .btn {
    flex-direction: column;

    button {
      width: 100%;
    }

    button:first-child {
      margin-bottom: 20px;
    }
  }

  .line {
    margin: 30px -15px;
  }
}
