@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapperUnified {
  display: flex;
  align-items: center;
}

.unified {
  color: $black;
}

.switch {
  margin: 10px;
}

.timzone-select {
  margin-bottom: 30px;
}

.customizeTitle {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 685px) {
  .wrapperUnified {
    width: 100%;
    margin-top: 5px;
  }
}

@media screen and (max-width: 500px) {
  .customizeTitle {
    flex-direction: column;
    align-items: flex-start;
  }
}
