@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';

.wrapper {
  min-height: 100vh;
}

.layout {
  background: $white;
  padding: 40px 100px 40px 79px;
  min-width: 80%;
}

.menu {
  flex: 0 0 88px !important;
  width: auto !important;
  min-width: initial !important;
  max-width: initial !important;
}
