@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.customAlert {
  @include title;
  position: absolute;
  min-width: 300px;
  padding: 30px 20px;
  background: $white;
  border-radius: 6px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  display: flex;
  gap: 20px;
  right: 0;

  .exclamation {
    @include icon(20px);
    min-width: 20px;
    margin-top: 3px;
  }

  p {
    @include title;
    min-width: 180px;
    max-width: 280px;
    margin-bottom: 0;
  }

  .close {
    @include icon(20px);
    min-width: 20px;
    margin-top: 3px;
    cursor: pointer;
  }

  &.success {
    .exclamation {
      path {
        fill: $green;
      }
    }
  }

  &.error {
    .exclamation {
      path {
        fill: $error-color;
      }
    }
  }

  &.warning {
    .exclamation {
      path {
        fill: $orange;
      }
    }
  }

  &.info {
    .exclamation {
      path {
        fill: $primary-color;
      }
    }
  }

  $sizes: 440px, 420px, 400px, 380px, 360px, 340px, 320px;

  @each $size in $sizes {
    @media screen and (max-width: $size) {
      max-width: calc($size - 40px);
      min-width: none;
    }
  }
}

.getError {
  min-width: 370px;
}

@media screen and (max-width: 750px) {
  .customAlert {
    min-width: auto;

    p {
      min-width: auto;
    }
  }
}
