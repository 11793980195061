@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  @include border($light-grey, 8px);
  background: $white;
  padding: 30px 40px;
  position: relative;

  svg {
    @include icon(24px);
  }

  .icon {
    position: absolute;
    top: calc(50% - 9.5px);
    left: -13px;
  }

  .block {
    display: flex;
    justify-content: space-between;
  }

  .description {
    @include text;
    color: $black;
    margin-top: 15px;
  }
}

.title {
  b {
    @include title;
    color: $black;
  }
}

.price {
  display: flex;

  > div {
    @include title;
  }

  .oldPrice {
    color: $dark-grey;
    margin-right: 10px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $dark-grey;
      top: 9px;
    }
  }
}

.time {
  @include text;
  color: $text-grey;
  margin-left: 20px;
}

.remove {
  position: absolute;
  top: calc(50% - 11px);
  right: -11px;
  cursor: pointer;
}

@media screen and (max-width: 685px) {
  .wrapper {
    padding: 20px;

    .icon {
      @include icon(16px);
      left: -9px;
    }
  }

  .block {
    > div {
      display: flex;
      flex-direction: column;

      span {
        margin-left: 0;
        margin-bottom: 5px;
      }
    }

    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    .description {
      margin-top: 0;
    }
  }
}
