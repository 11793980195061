.four {
  margin-bottom: 60px;

  h2 {
    margin-bottom: 40px;
  }

  .picWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    max-height: calc(178px * 2 + 10px);
    overflow: auto;

    img {
      width: 239px;
      height: 178px;
      border-radius: 4px;
    }
  }
}
