.avatar {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 54px;
    height: 45px;
    background-image: url(../../assets/images/common/redesign/plugAvatar.svg);
    background-size: contain;
    background-repeat: no-repeat;
    left: 50%;
    top: 50%;
    margin-left: -27px;
    margin-top: -22px;
  }

  &:global {
    &.ant-avatar-image {
      &::before {
        display: none;
      }
    }
  }
}
