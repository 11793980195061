@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography-mixins.scss';

.hint {
  @include text;
  color: $dark-grey;
  padding-top: 10px;
}

.modalWrap {
  :global {
    .ant-modal {
      .ant-slider-handle {
        border: solid 2px $turquoise;
      }

      .ant-slider-track {
        background-color: $turquoise;
      }

      .ant-modal-content {
        border-radius: 10px;

        .ant-modal-close {
          @include icon(26px);
          background-color: $light-grey;
          border-radius: 50%;
          right: -4px;
          top: -4px;

          .ant-modal-close-x {
            @include icon(26px);
            line-height: initial;
            right: -4px;
            top: -4px;

            svg {
              fill: $white;
              padding-top: 2px;
            }
          }
        }
      }

      .ant-modal-header {
        border-bottom: 1px solid $border;
        padding: 30px 60px 30px 60px;
        border-radius: 10px 0 0 0;

        .ant-modal-title {
          @include h2;
        }
      }

      .ant-modal-footer {
        display: flex;
        align-items: center;
        border-top: 1px solid $border;
        padding: 30px 60px 30px 60px;

        button:first-child {
          @include border($dark-grey, 25px);
          color: $black;
          background-color: $white;

          .text {
            color: $black;
          }

          &:hover {
            span {
              color: $white;
            }

            border: 1px solid transparent;
            background-color: $turquoise;
          }

          &:disabled {
            background-color: $light-grey;
            border: 1px solid $dark-grey;
            color: $dark-grey;
          }
        }

        button {
          @include text;
          height: 49px;
          color: $white;
          background-color: $turquoise;
          border-radius: 25px;
          padding: 0 60px;
          transition: 0.4s;
          display: block;
          border: 1px solid transparent;

          .text {
            color: $white;
          }

          &:hover {
            background-color: $btnPrimary-hover;
          }

          &:disabled {
            background-color: $btnPrimary-disable;
            color: $grey;
          }
        }
      }
    }
  }
}

.wrappCircle {
  :global {
    .reactEasyCrop_CropArea {
      border-radius: 50%;
    }
  }
}

@media screen and (max-width: 850px) {
  .modalWrap {
    :global {
      .ant-modal {
        .ant-modal-header {
          padding: 30px;
        }

        .ant-modal-footer {
          display: flex;
          align-items: center;
          padding: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .modalWrap {
    :global {
      .ant-modal {
        .ant-modal-footer {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;

          button {
            width: 100%;
          }

          button:last-child {
            margin-left: 0;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
