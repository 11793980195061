@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  display: grid;
  grid-gap: 30px 20px;
  grid-template-columns: repeat(2, calc(50% - 10px));
  margin-top: 30px;

  .bold {
    div {
      @include h2;
    }
  }

  textarea {
    min-height: 150px;
  }
}
