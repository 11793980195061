@import 'assets/scss/colors.scss';
@import 'assets/scss/typography.scss';

.icon {
  @include icon(20px);
  cursor: pointer;

  path {
    fill: $dark-grey;
  }
}

.name {
  display: flex;
  align-items: center;
  gap: 15px;

  p {
    @include desc-1;
  }
}

.main-row-open {
  border-bottom: 0 !important;
}

.child-row {
  border-bottom: 0 !important;
  cursor: pointer;

  td:first-child {
    padding-left: 50px;
  }

  &.last-row {
    border-bottom: 1px solid $admin-table-border-color !important;
  }
}
