@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.timeWrapper {
  display: flex;
  align-items: center;
  height: 62px;
  border-bottom: 1px solid $border;
  font-size: 17px;

  > div {
    width: 50%;
    display: flex;
  }
}

.text {
  @include text;
  color: $black;
  max-width: 150px;
  width: 100%;
}

.time {
  @include text;
  color: $grey;
}

.break {
  margin-left: 60px;
  min-width: 65px;
}

.shifts {
  margin-top: 40px;
}

@media screen and (max-width: 800px) {
  .timeWrapper {
    .text {
      max-width: 111px;
    }

    .textBreak {
      max-width: 70px;
    }
  }
}

@media screen and (max-width: 660px) {
  .timeWrapper {
    &:first-child {
      padding-top: 0;
    }

    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 20px 0;

    > div:first-child {
      margin-bottom: 5px;
    }

    > div {
      width: 100%;
    }

    .textBreak {
      max-width: 111px;
    }
  }

  .shifts {
    margin-top: 30px;
    width: 100%;

    button {
      width: 100%;
    }
  }
}
