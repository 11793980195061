@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.listWrapp {
  > div:not(:last-child) {
    margin-bottom: 30px;
  }

  margin-bottom: 10px;
}

.wrapper {
  background-color: $white;
  padding: 30px 40px;
  border: 1px solid $border;
  border-radius: 15px;

  > div {
    display: flex;
  }

  .left {
    max-width: 245px;
    width: 100%;
    padding-right: 30px;
    border-right: 1px solid $border;

    .name {
      @include h2;
      @include text-nowrap();
      font-weight: 400;
      max-width: 75%;
      color: $black;
      margin-bottom: 10px;
    }

    .phone {
      @include text;
      color: $grey;
    }
  }

  .rigth {
    padding-left: 30px;
    width: 100%;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    p:last-child {
      max-width: 77%;
      word-break: break-word;
    }

    p {
      @include text;
      color: $black;
    }

    .operationName {
      @include h2;
      font-weight: 400;
      color: $black;
      max-width: 70%;
    }

    .date {
      @include h2;
      font-weight: 400;
      color: $black;
    }

    .answer {
      @include text;
      color: $grey;
      margin-top: 20px;
      max-width: 99%;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .visitStatus {
    display: flex;
    align-items: center;
    margin-top: 15px;

    p {
      margin-left: 15px;
      @include desc-1;
      color: $black;
    }

    .visitInactive {
      color: $error-color;
    }
  }
}

@media screen and (max-width: 1300px) {
  .wrapper {
    padding: 20px;

    > div {
      flex-direction: column;
    }

    .left {
      max-width: 100%;
      padding-right: 0;
      padding-bottom: 15px;
      border-right: none;
      border-bottom: 1px solid $border;
    }

    .rigth {
      padding-left: 0;
      padding-top: 15px;

      .operationName {
        max-width: 60%;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .wrapper .rigth {
    .row:first-child {
      align-items: flex-start;
      flex-direction: column;

      p {
        margin-bottom: 10px;
      }
    }

    .operationName {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    .rigth {
      .answer {
        -webkit-line-clamp: 4;
      }
    }
  }
}
