@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.number {
  @include border($input-border, 5px);
  @include text;
  height: 42px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-top: 2px;
}

.phoneInput {
  margin: 0;
}

.wrappBtn {
  display: flex;
  margin-top: 10px;

  > button {
    margin-right: 32px;
  }
}
