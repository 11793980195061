@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  width: 100%;
  height: 100%;

  .header {
    @include center-between();
    width: 100%;
    height: 76px;
    padding: 0 30px 0 35px;
    border-bottom: 1px solid $light-grey;

    .titleHeader {
      @include h1;
      color: $black;
      white-space: nowrap;
    }

    .contentCenter {
      margin: 0 auto;
    }
  }

  .sidebar {
    max-width: 360px;
    width: 100%;
    min-height: calc(100vh - 76px);
    border-right: 1px solid $light-grey;
    padding: 30px 30px 30px 35px;

    .sidebarTitle {
      @include h2;
      color: $black;
      margin-bottom: 25px;
    }
  }

  .contentWrapp {
    display: flex;
    width: 100%;
    height: calc(100% - 76px);
    overflow-y: hidden;

    .content {
      width: 100%;
      padding: 30px 136px;
      background-color: $bg-grey-2;
    }
  }
}

@media screen and (max-width: 1350px) {
  .wrapper {
    .header {
      padding: 0 20px;
    }

    .contentWrapp {
      .sidebar {
        padding: 30px 20px 30px 20px;
        min-width: 240px;
        max-width: 240px;
      }

      .content {
        padding: 30px 20px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    margin-top: 13px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    position: relative;

    .contentWrapp {
      .sidebar {
        max-width: 100%;
      }

      .hideScroll {
        overflow: hidden;
        max-height: 100vh;
      }

      .content {
        display: none;
      }

      .mobileInitial {
        display: block;
      }

      .content.active {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        min-height: 100vh;
        height: 100%;
        z-index: 10;
        height: fit-content;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .header {
    margin-top: 0px;
  }
}
