@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.outsideWrapp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  z-index: 9990;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  height: 100vh;
  cursor: pointer;
}

.drawerWrapp {
  position: relative;
  height: 100vh;
  background-color: $white;
  max-width: 720px;
  cursor: initial;

  > div {
    display: flex;
    height: 100%;
  }
  .contentWrapp {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 90px);
    overflow-y: auto;
    margin-right: -30px;
    padding-right: 30px;
    padding-bottom: 10px;
    margin-left: -30px;
    padding-left: 30px;
  }
}

.appointment {
  width: 380px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  .header {
    @include center-between;
    margin-bottom: 30px;

    > div {
      position: relative;
    }

    h2 {
      margin: 0;
    }

    svg {
      cursor: pointer;
    }
  }

  .validateContent {
    h2 {
      display: flex;
      align-items: center;
      width: fit-content;
      cursor: pointer;
      position: relative;
    }
  }

  .tab {
    .tab__warning-alert {
      @include fonts(16px, 400, 'Nunito Sans');
      line-height: 24px;
      display: flex;
      align-items: flex-start;
      color: $alert-orange;
      gap: 5px;
      margin-bottom: 10px;

      .tab__warning-alert__icon {
        svg {
          @include icon(24px);
        }
      }
    }

    :global {
      .ant-tabs-nav-wrap {
        padding: 0;
        width: 100%;
        height: 40px;
      }

      .ant-tabs-nav-list {
        width: 100%;
        justify-content: space-between;
      }

      .ant-tabs-tab {
        div {
          @include text;
        }

        padding: 0;
        color: $dark-grey;

        &.ant-tabs-tab-active div {
          color: $black;
        }
      }

      .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        background-color: $turquoise;
      }
    }
  }
}
.appointmentMobile {
  position: absolute;
  z-index: 10;
  background-color: $white;
  bottom: 0;
  top: 0;

  .header {
    justify-content: center;

    > div {
      width: 100%;
      text-align: center;

      span {
        position: absolute;
        left: 0;
      }
    }

    > div:last-child {
      display: none;
      width: fit-content;
      margin-left: auto;
    }
  }
}

.footer {
  margin-left: -30px;
  margin-right: -30px;
  padding: 20px 30px 15px 30px;
  border-top: 1px solid $border;
  margin-top: auto;

  .total {
    @include center-between;
    @include title;
    color: $dark-grey;

    span {
      color: $black;
      font-weight: 600;
    }
  }

  .btn {
    @include center-between;
    margin-top: 20px;

    button {
      width: 100%;
      padding: 0;
      text-align: center;
    }

    button:last-child {
      margin-left: 10px;
    }
  }
}

.wrappPicker {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 20px 0;

  > div > div {
    padding-right: 20px !important;
  }

  svg {
    position: absolute;
    pointer-events: none;
    right: 0px;
    margin-left: 9px;
  }

  .datePicker {
    border: none !important;
    padding: 0;
    width: fit-content;

    :global {
      input {
        @include h2;
        width: 125px;
        text-transform: capitalize;
      }

      .ant-picker-suffix {
        display: none;
      }
    }
  }
}

.wrappPickerError {
  margin: 0;
}

.timepick {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  > div {
    width: 44%;

    :global {
      .ant-select {
        width: 100%;
      }
    }
  }

  .dash {
    width: 6px;
    height: 2px;
    background-color: $turquoise;
    margin-top: 29px;
  }

  :global {
    .ant-select {
      width: 137px;

      input {
        height: 100%;
      }

      &.ant-select-open {
        .ant-select-arrow {
          &::after {
            transform: initial;
          }
        }
      }

      .ant-select-arrow {
        top: 55%;

        &::after {
          @include icon(17px);
          background-image: url('../../assets/images/common/redesign/time.svg');
        }
      }
    }
  }
}

.mb20 {
  margin-bottom: 20px;
}

.select {
  &:global {
    &.ant-select-open {
      .ant-select-arrow {
        &::after {
          transform: initial;
        }
      }
    }
  }
  :global {
    .ant-select-selector .ant-select-selection-search input {
      height: 100% !important;
    }

    .ant-select-arrow {
      top: 55% !important;

      &::after {
        width: 17px !important;
        height: 17px !important;
        background-image: url('../../assets/images/common/redesign/search.svg') !important;
      }
    }
  }
}

.serviceAddBtn {
  margin: 20px 0px;
}

.dashLine {
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: $light-grey;
}

.servicesWrapper {
  position: relative;

  .serviceDelete {
    position: absolute;
    right: 0;
    top: -4px;
    z-index: 10;
    cursor: pointer;

    svg path {
      fill: $error-color;
    }
  }
}

@media screen and (max-width: 767px) {
  .appointment {
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    padding-top: 0;
  }

  .header {
    position: sticky;
    width: calc(100vw - 60px);
    top: -1px;
    padding: 30px 0;
    background-color: $white;
    z-index: 10;
    margin-bottom: 0 !important;
  }

  .mobile-padding {
    margin-bottom: 210px;
  }

  .drawerWrapp {
    width: 100%;
    max-width: 100%;

    .contentWrapp {
      height: 100%;
      overflow: initial;
    }
  }

  .appointment {
    width: 100vw;
  }

  .footer {
    position: fixed;
    z-index: 500;
    bottom: 0;
    width: 100%;
    background-color: $white;
  }
}
