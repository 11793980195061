@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.header {
  @include center-between;
  padding: 40px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 3;
}

.filter {
  > div {
    width: 178px;
  }
}

.navigate {
  @include border($input-border, 10px);
  display: flex;
  height: 42px;
  align-items: center;

  .arrow:first-child {
    border-radius: 10px 0 0 10px;
  }

  .arrow:last-child {
    border-radius: 0 10px 10px 0;
  }

  .arrow {
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    transition: 0.4s;

    svg {
      margin: 0 16px;
    }
    &:hover {
      background-color: $btn-hover-light;
    }
  }

  .date {
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid $input-border;
    border-left: 1px solid $input-border;

    > div:first-child {
      border-right: 1px solid $input-border;
    }

    .calendarWrap {
      height: 100%;

      .dateTitle {
        min-width: 206px;
        justify-content: center;
      }
    }

    .dateTitle {
      @include text;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 30px;
      transition: 0.4s;
      cursor: pointer;

      &:hover {
        background-color: $btn-hover-light;
      }

      .weekTitle {
        @include desc-1;
        padding-top: 2px;
      }
    }
  }
}

.modeWrap {
  display: flex;
  align-items: center;
}
.mode {
  display: flex;
  height: 42px;
  margin-right: 62px;

  div:not(:last-child) {
    margin-right: 10px;
  }

  div {
    @include border($input-border, 10px);
    @include text;
    display: flex;
    align-items: center;
    padding: 0 25px;
    color: $black;
    transition: 0.4s;
    cursor: pointer;
  }

  .active,
  div:hover {
    background-color: $btn-active-color;
    color: $white;
    border: 1px solid $btn-active-color;
  }
}

.calendarWrap {
  position: relative;

  .calendar {
    position: absolute;
    z-index: 10;
    left: -145px;
    top: 75px;
  }

  .month {
    top: 58px;
  }
}

.button {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 7px;
  }

  div {
    @include text;
  }
}

.shiftHeader {
  .mode {
    margin-right: 0;
  }

  > div:nth-child(1) {
    order: 2;
  }

  > div:nth-child(2) {
    order: 1;
  }

  > div:nth-child(3) {
    order: 3;
  }
}

.calendarStyle {
  min-height: 410px;

  :global {
    .fc-day-past .fc-daygrid-day-number div {
      opacity: 1;
    }

    .fc-view-harness {
      height: 280px !important;
    }
  }
}

.viewWeek {
  :global {
    tr td {
      .fc-event-resizable {
        border: none;
      }
    }
  }
}

.monthCalendarWrapp {
  width: 306px;

  :global {
    .ant-picker {
      height: 0;
      border: none;
      padding: 0;
      margin-left: 45px;
    }

    .ant-picker-suffix {
      display: none;
    }

    .ant-picker-input input::placeholder {
      color: transparent;
    }

    .ant-picker-input,
    .ant-picker-input-placeholder {
      > input {
        color: transparent;
        font-size: 1px;
      }
    }
  }
}

:global {
  .monthCalendar {
    .ant-picker-panel-container {
      .ant-picker-month-panel {
        width: 300px;
      }

      .ant-picker-header {
        padding: 30px 40px 10px 40px;

        .ant-picker-year-btn {
          @include h3;
          color: $calendar-dark-grey-text-color;
        }

        button {
          > span {
            &::after {
              display: none;
            }
          }
        }
      }
      .ant-picker-cell-selected .ant-picker-cell-inner {
        color: $turquoise !important;

        &::before {
          border-radius: 45%;
          width: 62px;
          height: 54px;
          top: -12px;
          left: -1px;
        }
      }

      .ant-picker-content .ant-picker-cell-inner {
        @include title;
        color: $calendar-dark-grey-text-color;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .header {
    margin: 0;
  }

  .appointmentHeader {
    padding: 25px 20px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08);

    .filter {
      > div {
        width: 130px;
      }
    }

    .mode {
      margin-right: 5px;

      > div {
        padding: 0 20px;
      }
    }

    .date {
      .dateTitle {
        min-width: 95px;
        padding: 0 15px;
      }

      .calendarWrap {
        .dateTitle {
          min-width: 95px;
          padding: 0 15px;
        }
      }
    }
  }

  .appointmentHeader {
    .calendarWrap {
      .calendar {
        left: -175px;
      }

      .month {
        left: -193px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .shiftHeader {
    padding: 25px 20px;
  }

  .appointmentHeader {
    .filter {
      > div {
        width: 178px;
      }
    }

    .mode {
      margin-right: 0px;

      > div {
        padding: 0 25px;
      }
    }

    .date {
      .calendarWrap {
        .dateTitle {
          span {
            @include text;
          }
        }
      }
    }
  }

  .appointmentHeader {
    .calendarWrap {
      .calendar {
        left: -165px;
      }

      .month {
        left: -189px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .appointmentHeader {
    flex-wrap: wrap;

    > div:last-child {
      order: 3;
    }

    .filter {
      order: 1;
    }

    .mode {
      width: 100%;
      order: 4;
      margin-top: 20px;

      > div {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0;
      }
    }

    .navigate {
      order: 2;
    }
  }
}

@media screen and (max-width: 767px) {
  .shiftHeader {
    padding: 20px 15px;
    flex-wrap: wrap;

    > div:nth-child(1) {
      order: 1;
      width: 100%;
      margin-bottom: 20px;

      .date {
        width: 100%;

        .dateTitle {
          max-width: 115px;
          padding: 0 20px;
        }

        .calendarWrap {
          width: 100%;

          .dateTitle {
            max-width: 100%;
            min-width: 100%;

            .weekTitle {
              padding-top: 2px;
            }
          }
        }
      }

      .arrow {
        min-width: 40px;
      }
    }

    > div:nth-child(2) {
      order: 2;
    }

    > div:nth-child(3) {
      order: 3;
    }

    .date {
      position: relative;
    }

    .calendarWrap {
      position: initial;

      .calendar {
        left: calc(50% - 155px);
        right: 0;
        max-width: 310px;

        > div {
          min-width: 310px;
          padding: 20px 15px 15px 15px;
          min-height: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .appointmentHeader {
    flex-direction: column;

    .navigate {
      order: 1;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;

      .arrow {
        width: 47px;
      }

      .date {
        width: 88%;

        .dateTitle {
          justify-content: center;
        }
      }

      .calendarWrap {
        width: 100%;
      }
    }

    .filter {
      order: 2;
      width: 100%;

      > div {
        min-width: 100%;
      }
    }

    .mode {
      order: 3;
      margin-bottom: 20px;
    }

    > div:last-child {
      order: 4;
      width: 100%;

      button {
        padding-left: 0;
      }
    }

    .calendarWrap {
      .calendar {
        left: -155px;
        width: 100vw;

        .calendarStyle {
          min-width: 340px;
          max-width: 340px;
          margin: 0 auto;
        }
      }

      .month {
        left: -193px;

        .monthCalendarWrapp {
          width: 100%;
        }

        :global {
          .monthCalendar {
            left: 50% !important;
            transform: translateX(-38%);
          }
        }
      }
    }
  }
}
