@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.header {
  @include center-between;
  margin-bottom: 60px;

  button {
    width: 200px;
  }

  .back {
    width: 30px;
    height: 16px;
    cursor: pointer;

    path {
      fill: $turquoise;
    }
  }

  button {
    @include center;
    @include title;
    gap: 10px;
  }
}

.inputWrapp {
  display: grid;
  grid-gap: 30px 20px;
  grid-template-columns: repeat(3, 1fr);
}

.btn {
  margin-top: 50px;
}
