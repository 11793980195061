@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.list {
  padding: 20px 15px;
  background-color: $bg-grey-2;
  border-top: 1px solid $light-grey;
  max-height: 350px;
  overflow-y: auto;
}

@media screen and (max-width: 1024px) {
  .calendarWrapp {
    height: fit-content;

    :global {
      .ant-spin-nested-loading {
        height: 100%;
      }

      .ant-spin-container {
        height: 100%;
      }
    }
  }
}
