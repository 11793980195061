@import 'assets/scss/typography.scss';

.first {
  margin-bottom: 60px;

  h2 {
    margin-bottom: 40px;
  }

  p {
    @include text;
    color: $dark-grey;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    gap: 100px;

    .form {
      max-width: 504px;
      width: 100%;

      & > div {
        width: 100%;
      }
    }
  }

  .imgWrapper {
    img {
      @include icon(280px);
      border-radius: 50%;
      opacity: 0.5;
    }
  }

  .phone {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
