@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  margin-top: 90px;

  :global {
    .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-nav-wrap {
      height: 76px;
      padding: 0;
      border-bottom: 1px solid $light-grey;
    }

    .ant-tabs-nav-operations {
      button {
        display: none;
      }
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 60px;
    }

    .ant-tabs-tab {
      span {
        @include h2;
        color: $dark-grey;
      }
    }

    .ant-tabs-tab-active {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 4px;
        background-color: $btn-active-color;
        border-radius: 3px;
        z-index: 5;
      }

      span {
        @include h2;
        color: $black;
      }
    }

    .ant-tabs-ink-bar {
      width: 0 !important;
    }

    .ant-tabs-extra-content {
      border-bottom: 1px solid $light-grey;
    }
  }
}
