@import 'assets/scss/mixins.scss';

.helpForBusiness {
  .titleWrapper {
    @include center-between;
    margin-bottom: 60px;

    .titleActions {
      @include center;
      gap: 30px;
    }
  }

  .table {
    tr {
      cursor: pointer;
    }
  }
}
