@import 'assets/scss/typography.scss';

.feedbackModal {
  .modalBody {
    min-width: 640px;
    max-width: 640px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .modalQuestion {
      @include desc-1;
      max-height: 80px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  @media screen and (max-width: 767px) {
    .modalBody {
      min-width: 100%;
    }
  }

  @media screen and (max-width: 500px) {
    .button {
      width: 100%;
    }

    .modalBody {
      .modalQuestion {
        max-height: 176px;
      }
    }
  }
}
