@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';

.sortWrapper {
  display: flex;
  align-items: center;
  gap: 30px;

  .sortContainer {
    display: flex;
    align-items: center;
    gap: 20px;

    .sortItem {
      color: $grey;
      cursor: pointer;
      user-select: none;
      -moz-user-select: none;
    }

    .active {
      font-weight: 600;
      color: $black;
    }

    .arrow {
      @include transition(transform, 0.2s, ease-in-out);
      transform: rotate(270deg);
      margin-left: 5px;
      padding: 1px;

      path {
        fill: $dark-grey;
      }
    }

    .arrowUp {
      @include transition(transform, 0.2s, ease-in-out);
      transform: rotate(90deg);
    }
  }

  @media screen and (max-width: 1024px) {
    gap: 20px;

    .sortContainer {
      gap: 15px;
    }
  }
}
