@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin border($borderColor, $brad: 0) {
  border: 1px solid $borderColor;
  border-radius: $brad;
}

@mixin fonts($size: 22px, $weight: 400, $family: 'Proxima Nova') {
  font-size: $size;
  font-weight: $weight;
  font-family: $family, sans-serif;
}

@mixin icon($size) {
  width: $size;
  height: $size;
}

@mixin text-nowrap($textOverflow: ellipsis) {
  white-space: nowrap;
  text-overflow: $textOverflow;
  overflow-x: hidden;
}

@mixin rotate($deg: 0deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin transition($name, $duration, $easing) {
  -webkit-transition: $name $duration $easing;
  -moz-transition: $name $duration $easing;
  -o-transition: $name $duration $easing;
  transition: $name $duration $easing;
}
