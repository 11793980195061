@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.adminSalonsPage {
  h4 {
    margin-bottom: 60px;
  }

  .table {
    tr {
      img,
      svg {
        @include icon(44px);
        border-radius: 50%;
        margin-right: 24px;
      }
    }

    tbody tr {
      cursor: pointer;
    }
  }
}
