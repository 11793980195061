@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';

.managerHeader {
  @include center-between;

  .logo {
    width: 32px;
    height: 27px;

    path:first-child {
      fill: $turquoise;
    }
  }

  .logout {
    @include icon(28px);
  }

  @media screen and (max-width: 500px) {
    .logo {
      width: 28px;
      height: 24px;
    }

    .logout {
      @include icon(24px);
    }
  }
}
