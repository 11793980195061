@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  min-width: 940px;
}

.line {
  background-color: $border;
  height: 1px;
  margin: 30px -60px 30px -60px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .btn {
    display: flex;
    > button {
      margin-left: 20px;
    }
  }

  .selectTitle {
    @include text;
    color: $black;
    margin-bottom: 5px;
  }

  .select {
    width: 100%;

    :global {
      .ant-select-selection-item {
        text-transform: capitalize;
      }
    }

    > div {
      display: flex;
      align-items: flex-end;
      width: 100%;

      > div {
        max-width: 150px;
        margin-right: 20px;
      }
    }

    :global {
      .ant-select {
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .wrapper {
    min-width: 100%;
  }
}

@media screen and (max-width: 1010px) {
  .footer {
    flex-direction: column;

    .select {
      > div {
        width: 100%;

        > div:not(:first-child) {
          margin-left: 20px;
        }

        > div {
          width: 50%;
          max-width: 50%;
          margin-right: 0;
        }
      }
    }
    .btn {
      margin-top: 30px;
      width: 100%;
      justify-content: space-between;

      button:first-child {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .line {
    display: none;
  }

  .wrapper {
    margin-top: -20px;
  }

  .footer {
    margin-top: 30px;

    .select {
      > div {
        > div {
          width: 100%;
          max-width: 100%;
        }
      }

      :global {
        .ant-select {
          margin-right: 0;
        }
      }

      > div {
        flex-direction: column;

        > div:not(:first-child) {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }

    .btn {
      flex-direction: column;

      button:first-child {
        margin-left: 0;
        margin-bottom: 20px;
      }

      button {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
