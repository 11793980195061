@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;

  .btn {
    margin-top: 30px;
  }

  :global {
    .ant-spin-nested-loading {
      width: 100%;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    @include h1;
    color: $black;
  }
}

.btnDelete {
  color: $error-color;
}

@media screen and (max-width: 1350px) {
  .wrapper {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin-top: 13px;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding: 20px 5px;
  }
  .header {
    button p {
      display: none;
    }
    svg {
      margin-right: 0;
    }
  }
  .btn {
    width: 100%;
    button {
      width: 100%;
    }
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    margin-top: 0px;
  }
}
