@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.title {
  @include h2;
  margin-bottom: 1px;
}

.subTitle {
  @include text;
  color: $text-grey;
  margin-bottom: 30px;
}

.texarea {
  margin-bottom: 30px;

  textarea {
    min-height: 186px;
  }

  width: 100%;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
  padding: 0 30px;
}

@media screen and (max-width: 1024px) {
  .header {
    padding: 20px;
    margin-top: 14px;
  }

  .wrapper {
    padding: 0 20px;
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    padding: 0 15px;
  }

  .header {
    margin-top: 4px;
    padding: 20px 15px;
  }
}
