@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.btn {
  cursor: pointer;
  outline: none;
  border: none;
  background: none;

  &.text {
    display: flex;
    align-items: center;
    transition: 0.4s;

    svg {
      margin-right: 7px;
    }

    p {
      @include text;
    }

    &:hover {
      svg path {
        fill: $btnPrimary-hover;
      }
    }

    &:disabled {
      svg path {
        fill: $btnPrimary-disable;
      }

      cursor: initial;
    }
  }

  &.primary {
    @include text;
    height: 49px;
    color: $white;
    background-color: $turquoise;
    border-radius: 25px;
    padding: 0 60px;
    transition: 0.4s;
    display: block;
    border: 1px solid transparent;

    .text {
      color: $white;
    }

    &:hover {
      background-color: $btnPrimary-hover;
    }

    &:disabled {
      background-color: $btnPrimary-disable;
      color: $grey;
    }
  }

  &.outline {
    @include border($dark-grey, 25px);
    @include text;
    height: 49px;
    color: $black;
    background-color: $white;
    padding: 0 60px;
    transition: 0.4s;
    display: block;

    .text {
      color: $black;
    }

    &:hover {
      .text {
        color: $white;
      }

      border: 1px solid transparent;
      background-color: $turquoise;
    }

    &:disabled {
      background-color: $light-grey;
      border: 1px solid $dark-grey;
      color: $dark-grey;
    }
  }
}

.green {
  transition: 0.4s;

  .text {
    color: $turquoise;
  }

  &:hover .text {
    color: $btnPrimary-hover;
  }

  &:hover {
    svg path {
      fill: $btnPrimary-hover;
    }
  }
}

.red {
  transition: 0.4s;

  .text {
    color: $error-color;
  }

  &:hover .text {
    color: $btnPrimaryRed-hover;
  }

  &:hover {
    svg path {
      fill: $btnPrimaryRed-hover !important;
    }
  }
}
