.aboutUsTemplate {
  button {
    margin-top: 10px;
  }

  h2 {
    margin-bottom: 20px;

    &:not(:first-child) {
      margin-top: 60px;
    }
  }
}
