@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.calendar {
  flex-grow: 1;
  margin: 0px 40px 50px;

  td {
    border-top: 0px solid;
    border-bottom: 0px solid;
  }
}

.resource {
  height: 60px;
}

.event {
  @include text;
  display: grid;
  place-items: center;
  height: 60px;
}

.nameWrap {
  width: 145px;

  span {
    @include center-between;
    width: 100%;
  }

  svg {
    margin-left: 10px;
    margin-top: -3px;
  }
}

.item {
  @include center-between;
  margin: 0px;
  width: 230px;

  .name {
    @include desc-1;
    @include text-nowrap;
    max-width: 92px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    color: $black;
    transition: 0.4s;
  }

  button:hover {
    color: $turquoise;
  }

  > button:first-child {
    border-bottom: 1px solid $light-grey;
    padding-bottom: 5px;
  }

  > button:last-child {
    padding-top: 5px;
  }
}

.wrapperTime {
  display: flex;
  align-items: center;
  margin-right: 15px;
  width: 120px;
}

.time {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid $light-grey;

  p {
    @include desc-1;
    margin: 5px 0px;
    color: $text-grey;
  }
}

@media screen and (max-width: 900px) {
  .item {
    flex-wrap: wrap;
    width: 112px;

    .nameWrap {
      @include center-between;
      border-bottom: 1px solid $border;
      width: 100%;

      svg {
        margin-left: 10px;
        margin-top: -3px;
      }
    }

    .name {
      max-width: 107px;
    }

    .wrapperTime {
      width: 100%;
    }

    .time {
      margin-top: 5px;
      padding-left: 0;
      margin-left: 0;
      border-left: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .item {
    .name {
      max-width: 88px;
    }
  }
}
