@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.table {
  tr td {
    &:first-child {
      padding-left: 30px;
      padding-right: 30px;
      width: 12px;
    }
    &:nth-child(2) {
      padding-left: 0;
    }
    &:last-child {
      text-align: right;
      padding-right: 30px;
    }
  }
}

.titleWrapper {
  margin-bottom: 60px;
}

.calendarWrapp {
  padding: 24px 30px;
  > div {
    max-width: 200px;
  }
}
