.alerts {
  position: fixed;
  top: 5%;
  width: 400px;
  z-index: 9999;

  .alertsWrapper {
    position: relative;
  }

  &.alertsCenter {
    right: 50%;
    transform: translate(38%, 0%);
  }

  &.alertsRight {
    right: 10%;
    transform: translate(18%, -50%);
  }

  &.alertsLeft {
    left: 10%;
    transform: translate(18%, -50%);
  }

  @media screen and (max-width: 800px) {
    &.alertsRight,
    &.alertsLeft {
      right: 10px;
      transform: initial;
    }

    .alerts {
      max-width: 250px;
      width: auto;
    }
  }
}
