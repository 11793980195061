@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/Proxima\ Nova\ Font.otf') format('opentype');
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../fonts/NeueHaasDisplayBold.ttf') format('truetype');

  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../fonts/NeueHaasDisplayBoldItalic.ttf') format('truetype');

  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../fonts/NeueHaasDisplayMediu.ttf') format('truetype');

  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../fonts/NeueHaasDisplayMediumItalic.ttf') format('truetype');

  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../fonts/NeueHaasDisplayLight.ttf') format('truetype');

  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('../fonts/NeueHaasDisplayLightItalic.ttf') format('truetype');

  font-weight: 400;
  font-style: italic;
}
