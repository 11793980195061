@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.title {
  @include h2;
  margin-bottom: 60px;
}

.wrapper {
  table tbody tr {
    cursor: pointer;

    &:hover {
      background: $admin-table-td-hover-color;
    }
  }

  :global {
    .ant-input-search {
      max-width: 370px;
      width: 100%;
      margin-left: auto;
      display: block;
    }
  }
}

.avatar {
  span {
    margin-right: 24px;
  }
}
