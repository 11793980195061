@import 'assets/scss/colors.scss';
@import 'assets/scss/typography.scss';

.managerTable {
  width: 100%;
  overflow: auto;

  table {
    width: 100%;

    thead {
      tr {
        background: $light-black;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08);

        th {
          @include text;
          color: $white;
          padding: 15px 20px;

          &:first-child {
            padding-left: 30px;
          }

          &:last-child {
            padding-right: 30px;
          }
        }
      }
    }

    tbody {
      tr {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          display: block;
          background: $light-grey;
          height: 1px;
          width: calc(100% - 60px);
          bottom: 0;
          left: 30px;
        }

        td {
          vertical-align: baseline;
          padding: 30px 20px;

          &:first-child {
            padding-left: 30px;
          }

          &:last-child {
            padding-right: 30px;
          }
        }
      }

      .emptyTable {
        text-align: center;
        color: $admin-table-secondary-text-color;
        padding: 50px 0;
      }

      .loader {
        text-align: center;
        padding: 50px 0;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    table {
      thead tr th {
        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }

      tbody tr td {
        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }
    }
  }
}
