@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  .label {
    @include text;
    margin-bottom: 8px;

    .necessaryLabel {
      @include text;
      color: $dark-grey;
    }
  }

  ::-webkit-scrollbar {
    width: 7px;
  }

  textarea {
    @include border($input-border, 5px);
    @include text;
    box-shadow: none;
    padding: 10px 15px;

    &:hover {
      @include border($input-border, 5px);
    }

    &:active,
    &:focus {
      @include border($btn-active-color, 5px);
      box-shadow: none;
    }
  }
}
