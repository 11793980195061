@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  width: 370px;
  height: 100%;
  border-right: 1px solid $border;
  padding: 30px;
  padding: 30px 15px 30px 30px;
  background-color: $bg-grey-2;

  .mainTitle {
    @include center-between;
    margin-bottom: 30px;

    h2 {
      margin: 0;
    }
  }

  .list {
    max-height: 90vh;
    overflow-y: auto;
    padding-right: 15px;
  }

  .item {
    @include border($border, 5px);
    cursor: pointer;
    padding: 15px 15px 10px 15px;

    &.active {
      @include border($turquoise, 5px);
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .titleWrapp {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
    }

    .title {
      @include desc-1;
      @include text-nowrap();
      max-width: 237px;
      color: $black;
    }

    .label {
      @include desc-2;
      color: $grey;
    }

    .dateWrap {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .title {
        @include desc-1;
      }

      .label {
        @include text-nowrap();
        max-width: 120px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    width: 100%;
  }
}
