@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography-mixins.scss';

.wrapBreak {
  max-height: 215px;
  overflow-y: auto;
}

.itemBreak {
  display: flex;
  align-items: center;
  margin-top: 20px;

  > button {
    margin-left: 30px;
    margin-top: 10px;
  }

  :global {
    .ant-picker {
      width: 110px;
    }
  }
}

.dash {
  margin: 10px 20px 0px 20px;
  width: 6px;
  height: 2px;
  background-color: $turquoise;
}

.text {
  @include text;
  margin: 5px 30px 0px 57px;
  color: $black;
}

@media screen and (max-width: 767px) {
  .wrapBreak {
    max-height: 275px;
    margin-right: -8px;
    padding-right: 5px;
  }

  .itemBreak {
    flex-wrap: wrap;
    position: relative;

    > div:nth-child(1) {
      order: 1;
      margin: 0;
      width: 100%;
      margin-bottom: 5px;
    }

    > div:nth-child(2) {
      order: 2;
      width: 50%;
    }

    > div:nth-child(4) {
      order: 3;
      width: 50%;

      > div {
        margin-left: auto;
      }
    }

    > button:nth-child(5) {
      order: 4;
      padding-left: 0;
      width: 100%;
      margin-left: 0;
      margin-top: 15px;
    }

    .dash {
      position: absolute;
      left: 49%;
      top: 47%;
      transform: translateY(-50%);
      margin: 0;
    }

    :global {
      .ant-picker {
        width: 80%;
      }
    }
  }
}
