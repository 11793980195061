@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  display: flex;
  align-items: flex-start;

  > div {
    @include icon(17px);
    margin-right: 10px;
  }
}
