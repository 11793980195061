@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography-mixins.scss';

.error {
  border: 1px solid $error-color;
}

.textError {
  @include desc-1;
  margin-bottom: 0;
  color: $error-color;
  margin-top: 10px;
}
