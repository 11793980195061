@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.adminSalonProfilePage {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;

    .btnLock {
      svg {
        margin-right: 0px;
      }

      p {
        @include h2;
        font-weight: 400;
      }
    }

    button {
      width: 200px;
    }

    .back {
      width: 30px;
      height: 16px;
      cursor: pointer;

      path {
        fill: $turquoise;
      }
    }

    button {
      @include center;
      @include title;
      gap: 10px;
    }
  }
}
