@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  padding: 15px 30px;
  background-color: $black;

  .header {
    @include center-between;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 40px;

    > p {
      @include text;
      color: $white;
      width: 22.4%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    .header {
      padding: 0 30px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .wrapper {
    .header {
      padding: 0 10px;

      > p:nth-child(1) {
        width: 20%;
      }

      > p:nth-child(2) {
        width: 20%;
      }

      > p:nth-child(3) {
        width: 20%;
      }

      > p:nth-child(4) {
        width: 28%;
      }

      > p:nth-child(5) {
        width: 20%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    display: none;
  }
}
