@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography-mixins.scss';

.modal {
  @include center();
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  &.errorWrapp {
    right: 720px;
  }
}

.modalContent {
  position: relative;
  border-radius: 10px;
  background: $white;
  cursor: default;
  padding: 30px 0px 30px 0px;

  .title {
    @include h2;
    display: flex;
    width: calc(100% + 120px);
    align-items: center;
    color: $black;
    line-height: 100%;
    min-height: 90px;
    border-bottom: 1px solid $border;
    margin: -20px -60px 30px -60px;
    padding: 30px 60px 30px 60px;

    > span:last-child {
      @include text-nowrap();
      max-width: 400px;
    }
  }

  .footer {
    margin: 30px -60px 0;
    padding: 30px 60px;
    border-top: 1px solid $border;
    padding-bottom: 0;
  }
}

.close {
  @include icon(26px);
  @include center();
  position: absolute;
  right: -4px;
  top: -4px;
  background-color: $light-grey;
  transition: 0.4s;
  z-index: 1;
  border-radius: 50%;
  cursor: pointer;

  svg {
    @include icon(12px);

    path {
      stroke: $white;
    }
  }

  &:hover {
    background-color: $turquoise;
  }
}

.title {
  .arrow {
    display: none;
  }
}

.scrollWrapp {
  padding: 0 60px;
  max-height: 92vh;
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 850px) {
  .scrollWrapp {
    padding: 0 30px;
  }
  .modalContent {
    padding: 30px 0;
    max-width: 95%;

    .footer {
      margin: 30px -30px 0;
      padding: 30px;
      padding-bottom: 0;
    }

    .title {
      margin: -20px -30px 30px -30px;
      padding: 30px 30px 30px 30px;
      width: calc(100% + 60px);
    }
  }
}

@media screen and (max-width: 767px) {
  .scrollWrapp {
    padding: 0 15px;
  }

  .wrappBack {
    background-color: transparent;
    top: 0;

    .modalContent {
      max-width: 100%;
      width: 100%;
      height: 100%;
      border-radius: 0;

      .close {
        display: none;
      }
    }
  }

  .modalContent {
    .footer {
      margin: 30px -30px 0;
      padding: 30px;
    }

    .title {
      position: relative;
      margin: -20px -15px 30px -15px;
      padding: 30px 15px 30px 15px;
      width: calc(100% + 30px);
      display: inherit;
      text-align: center;

      .arrow {
        height: 22px;
        display: initial;
        width: fit-content;
        margin-right: -14px;
        float: left;
        margin-top: -3px;
        margin-bottom: 8px;
      }

      span {
        @include text-nowrap();
        max-width: 98% !important;
        display: inline-block;
        vertical-align: bottom;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .wrappBack {
    .modalContent {
      min-height: calc(100vh - 75px);
    }
  }
}
