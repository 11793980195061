@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  display: flex;
  align-items: center;

  > div:first-child {
    position: relative;
  }
}

.switch {
  margin-left: 35px;
}

.wrapper_upload {
  min-height: 54px;
  cursor: pointer;

  :global {
    .ant-upload.ant-upload-select-picture-card {
      @include icon(55px);
      background-color: $white;
      border: none;
    }

    .ant-upload-list-picture-card-container {
      @include icon(55px);
    }

    .ant-upload-list-picture-card-container {
      @include icon(55px);
    }

    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      padding: 0;
      border-radius: 50%;
    }

    .ant-upload-list-picture .ant-upload-list-item-error,
    .ant-upload-list-picture-card .ant-upload-list-item-error {
      border-color: transparent;
    }

    .ant-tooltip-content {
      display: none;
    }
  }
}

.wrapper_item {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  height: 67px;
  overflow: hidden;

  :global {
    .ant-input {
      height: initial;
      padding: 0;
      border: none;
      margin-left: 10px;
      font-size: 16px;
    }
    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      margin-right: 0px;
      margin-bottom: 0px;
    }
  }
}

.uploadBtnWrapp {
  display: flex;
  align-items: center;
  padding: 5px 15px;

  > div {
    margin-left: 10px;
    width: 150px;
  }
}

.icon {
  position: absolute;
  top: 2px;
  right: -8px;
  z-index: 5;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  bottom: 6px;
  z-index: 5;
  left: -8px;
  background: $white;
  display: flex;
  border-radius: 50%;
}

.inputWrapper {
  display: flex;
  align-items: center;
  border: 1px solid $dark-grey;
  margin: 10px 0px;
  min-height: 45px;
  min-width: 350px;
  border-radius: 6px;

  > div {
    width: 100%;

    > div {
      @include text-nowrap();
      min-width: 55px;
      overflow-y: hidden;
    }
  }

  &.activeInput {
    border: 1px solid $turquoise;

    :global {
      .ant-upload-list-picture-card-container {
        margin: 0 8px 0px 0;

        .ant-upload-list-item-info {
          width: 54px;
        }
      }
    }
  }
}

.activeInputSubcategory {
  .wrapper_item {
    height: inherit;
  }

  .wrapper_upload {
    display: none;
  }
}

:global {
  .ant-tooltip {
    width: 300px;
    max-width: 300px;
    ul {
      padding-left: 15px;
    }
  }
}
