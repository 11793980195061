@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.headerWrapp {
  p {
    @include h3;
    color: $black;
  }
}

.contentWrapp {
  max-height: 300px;
  height: 100%;
  overflow: auto;
  padding-right: 5px;

  .item {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-bottom: 20px;

    .failed {
      color: $error-color;
    }

    .succesful {
      color: $turquoise;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
      border-bottom: 1px solid $light-grey;
    }

    p {
      @include text;

      &:not(:last-child) {
        padding-right: 15px;
      }
    }
  }
}
