@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  width: 710px;
}

.line {
  height: 1px;
  background-color: $border;
  margin: 30px -60px;
}

.btn {
  @include center-between;
}

.checkboxWrapp {
  p {
    margin-bottom: 16px;
  }

  label {
    margin-bottom: 10px;

    span {
      font-weight: 400;
    }
  }
}

.listCheckbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-left: -13px;
  max-height: 81px;
  overflow-y: auto;

  label {
    width: 32%;
    max-width: 32%;
    margin-bottom: 10px;

    span {
      font-weight: 400;
    }

    span:last-child {
      @include text-nowrap;
      max-width: 180px;
    }
  }
}

@media screen and (max-width: 850px) {
  .wrapper {
    width: auto;
  }

  .line {
    margin: 30px -30px;
  }
}

@media screen and (max-width: 767px) {
  .line {
    margin: 30px -15px;
  }
}

@media screen and (max-width: 650px) {
  .listCheckbox {
    max-height: 190px;
    margin-left: 0;

    label {
      width: 100%;
      max-width: 100%;
      margin-left: 0 !important;

      > span:last-child {
        max-width: 80%;
      }
    }
  }

  .btn {
    flex-direction: column;

    button {
      width: 100%;
    }

    button:first-child {
      margin-bottom: 20px;
    }
  }
}
