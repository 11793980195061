@import 'assets/scss/colors.scss';

.textTemplate {
  max-width: 1000px;
  width: 100%;

  .plusIcon {
    margin-right: 10px;

    rect {
      fill: $green;
    }

    path {
      fill: $white;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 30px;

    button {
      width: fit-content;
    }
  }
}
