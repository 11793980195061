.wrapper {
  position: relative;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .wrapper {
    margin: 15px 0;
  }
}
