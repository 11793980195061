@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  :global {
    .ant-tabs-tab-btn {
      @include title;
      color: $dark-grey;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      @include title;
      color: $black;
    }

    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        padding: 0;
        margin-bottom: 30px;
      }
    }
  }
}
