@import './colors.scss';
@import './mixins.scss';
@import './typography-mixins.scss';

h1,
.h1 {
  @include h1;
}

h2,
.h2 {
  @include h2;
}

h3,
.h3 {
  @include h3;
}

h4,
.h4 {
  @include h4;
}

h5,
.h5 {
  @include h5;
}

h6,
.h6 {
  @include h6;
}

.title {
  @include title;
}

p,
.text {
  @include text;
}

button,
.btn {
  @include fonts(16px);
  line-height: 19px;
}

.desc-1 {
  @include desc-1;
}

.desc-2 {
  @include desc-2;
}
