@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 26px;
  margin-bottom: 50px;
}

.button {
  color: $dark-grey;
  cursor: pointer;
}

.servicesCards {
  padding: 0px 50px;
  width: 100%;
}

.block {
  display: flex;
}

.categoriesCards {
  height: 100%;
  width: 100%;
}

.categories {
  font-size: 23px;
  margin-bottom: 30px;
}

.categoriesWrapper {
  border-right: 1px solid;
  border-color: $light-grey;
  width: 30%;
  min-height: 100%;
  position: relative;
}

.iconWrapper {
  width: 100%;
  cursor: pointer;
}

.category {
  @include border($border, 4px);
  display: flex;
  height: 52px;
  padding: 10px 8px;
  position: relative;
  min-height: 30px;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 8px;

  > span {
    svg {
      fill: $input-border;
    }
  }

  &.activeBorder {
    @include border($turquoise, 4px);

    span svg path {
      fill: $black;
    }

    .withoutCategory {
      color: $black;
    }
  }

  .icon {
    position: absolute;
    left: -8px;
    z-index: 1;
    top: 12px;
  }

  .withoutCategory {
    @include text;
    font-weight: 600;
    color: $input-border;
    margin-left: 10px;
  }
}

.activeBorder {
  border: 1px solid $border;
}

.disableActive {
  :global {
    .categoryWrapp {
      > div {
        border: 1px solid $dark-grey;

        svg path {
          fill: $dark-grey;
        }

        div {
          color: $text-grey;
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .servicesCards {
    padding: 0;
  }
}
