@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.subBlock {
  display: flex;
  gap: 30px;
  opacity: 1;
  width: 100%;

  .active {
    &.infoWrapper {
      @include border($black, 4px);
      opacity: 1;
      width: 100%;

      &.isError {
        border-color: $error-color;
      }

      .titleInput {
        @include h3;
        font-weight: 400;
        min-width: 242px;
        width: fit-content;
        margin-bottom: 20px;
      }

      .descriptionInput {
        margin-bottom: 25px;
      }

      .list {
        border: 1px solid $grey;
        padding: 20px;

        .listAdd {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 26px;

          .listAddInput {
            min-width: 204px;
            width: fit-content;
          }

          .plusIcon {
            @include icon(46px);
            cursor: pointer;

            rect {
              fill: $black;
            }

            path {
              fill: $white;
            }
          }
        }

        ul {
          li {
            overflow: hidden;

            p {
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .removeIcon {
              margin-left: auto;
              box-sizing: content-box;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .cardWrapper {
    min-width: 606px;
    max-width: 606px;
    width: 100%;
    width: fit-content;
    position: relative;

    &.isEdit {
      min-width: 810px;
      max-width: 810px;
    }

    .editButton,
    .deleteButton,
    .acceptButton,
    .closeBtn {
      @include icon(41px);
      @include center;
      position: absolute;
      right: -20px;
      border-radius: 50%;
      border: none;
      z-index: 1;
    }

    .acceptButton {
      top: -20px;
      background: $bg-grey-2;

      svg {
        @include icon(16px);

        path {
          stroke: $green;
        }
      }
    }

    .closeBtn {
      top: 30px;
      background: $bg-grey-2;

      svg {
        @include icon(16px);
      }
    }

    .editButton {
      top: -20px;
      background: $bg-grey-2;

      svg {
        margin: 0 0 4px 4px;
      }

      &:disabled {
        background: lightten($bg-grey-2, 2);

        svg {
          opacity: 0.6;
        }
      }
    }

    .deleteButton {
      top: 30px;
      background: lighten($bg-grey-2, 2);

      svg {
        @include icon(20px);
        opacity: 0.6;
      }

      &.isActive {
        background: $bg-grey-2;

        svg {
          opacity: 1;
        }
      }
    }
  }

  .infoWrapper {
    @include border($grey, 4px);
    position: relative;
    width: 100%;
    padding: 20px;
    opacity: 0.5;

    &.isRemoved {
      opacity: 0.2;
    }

    &.isError {
      border-color: $error-color;
    }

    h2 {
      margin-bottom: 4px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    p {
      margin-bottom: 25px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .list {
      margin-bottom: 50px;

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 17px;
        column-gap: 40px;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          @include text;
          display: flex;
          align-items: center;
          color: $black;
          overflow: hidden;

          p {
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 0;
          }

          svg {
            @include icon(24px);
            min-width: 24px;
            padding: 5px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .price {
    position: relative;
    display: flex;
    gap: 15px;
    align-items: center;

    .priceSelect {
      max-width: 140px;
    }

    .priceInput {
      max-width: 200px;
      width: 100%;

      input {
        @include text;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: -25px;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background: $text-grey;
    }
  }

  .input {
    @include title;
  }
}
