@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  width: 100%;

  :global {
    .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-nav-wrap {
      height: 76px;
      padding: 0 30px 0 35px;
      border-bottom: 1px solid $light-grey;
    }

    .ant-tabs-nav-operations {
      button {
        display: none;
      }
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 60px;
    }

    .ant-tabs-tab {
      span {
        @include h1;
        color: $dark-grey;
      }
    }

    .ant-tabs-tab-active {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 4px;
        background-color: $btn-active-color;
        border-radius: 3px;
        z-index: 5;
      }

      span {
        @include h1;
        color: $black;
      }
    }

    .ant-tabs-ink-bar {
      width: 0 !important;
    }

    .ant-tabs-extra-content {
      border-bottom: 1px solid $light-grey;
    }
  }
}

.wrapBtn {
  height: 76px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $light-grey;
  padding-right: 30px;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    margin-top: 13px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    :global {
      .ant-tabs-nav-wrap {
        height: 76px;
        padding: 0 25px 0 15px;
      }
    }

    .wrapBtn {
      padding-right: 15px;
    }
  }
}

@media screen and (max-width: 550px) {
  .wrapper {
    :global {
      .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 30px;
      }
    }
  }

  .wrapBtn.hide {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    margin-top: 0;
  }
}
