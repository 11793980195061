@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';

.badgeWrapper {
  display: flex;
  position: relative;
  width: fit-content;
  height: fit-content;

  .badge {
    @include icon(16px);
    @include center;
    position: absolute;
    background: $error-color;
    color: $white;
    top: -8px;
    right: -8px;
    border-radius: 50%;
    z-index: 1;
    font-size: 10px;

    .longOne {
      border-radius: 6px;
      width: 18px;
    }

    .longOnePlus {
      border-radius: 6px;
      width: 22px;
    }
  }
}
