@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';

.seven {
  margin-bottom: 60px;

  h2 {
    margin-bottom: 40px;
  }

  p {
    @include border($border, 4px);
    color: $grey;
    margin-bottom: 0;
    padding: 8px 12px;
  }
}
