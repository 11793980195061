@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.tooltipWrapp {
  overflow: initial;
}

.tooltip {
  margin-left: 10px;
  margin-bottom: 20px;
}

.tooltip {
  position: absolute;
  cursor: pointer;
  right: -21px;
  top: -3px;

  .tooltipContent {
    position: absolute;
    z-index: 10;
    display: none;
    width: 350px;
    background-color: $light-black;
    padding: 15px 30px;
    border-radius: 5px;
    left: 25px;
    top: -20px;

    &::before {
      content: '';
      position: absolute;
      border: 20px solid transparent;
      border-right: 20px solid $black;
      left: -26px;
    }

    div:first-child {
      margin-bottom: 10px;
    }

    div {
      @include desc-1;
      color: $white;
    }
  }

  &:hover .tooltipContent {
    display: block;
  }
}

@media screen and (max-width: 685px) {
  .tooltip .tooltipContent {
    width: 311px;
    padding: 15px 20px;
    left: -151px;
    top: 40px;

    &::before {
      border-right: 20px solid transparent;
      border-bottom: 20px solid $black;
      left: 139px;
      top: -29px;
    }
  }
}
