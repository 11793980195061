@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
  height: 54px;
  border-radius: 10px;
  background-color: $white;
  margin-bottom: 10px;
  cursor: pointer;

  .time {
    @include desc-2;
    display: flex;
    flex-direction: column;
    color: $black;

    span {
      @include desc-2;
      color: $black;
    }
  }

  .color {
    width: 3px;
    height: 100%;
    margin: 0 10px;
    border-radius: 5px;
  }

  .title {
    p {
      @include text-nowrap();
      max-width: 127px;
    }

    p:first-child {
      @include h5;
    }

    p:last-child {
      @include desc-2;
    }
  }
}
