.contactUsTemplate {
  .input,
  .textarea {
    max-width: 350px;
    width: 100%;
  }

  input {
    margin-bottom: 30px;
  }

  button {
    margin-top: 30px;
  }
}
