@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  margin-bottom: 30px;
  margin-top: 15px;

  h4 {
    margin-bottom: 0;
  }

  .title {
    & > div {
      &:first-child {
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;

        > span {
          width: fit-content;
          padding-right: 5px;
          flex: initial !important;
        }
      }

      & > div {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    div div span svg {
      margin-bottom: 4px;
    }

    div span {
      @include text;
    }
  }

  .cardWrapper {
    display: grid;
    flex-wrap: wrap;
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);

    &.cardWrapperNotTime {
      grid-template-columns: repeat(1, 1fr);
    }

    .hoursCard {
      @include text;
      background: $bg-grey-2;
      padding: 10px 15px;
      border-radius: 6px;
      color: $black;
      cursor: pointer;
      user-select: none;
      text-align: center;

      &:hover {
        opacity: 0.7;
      }

      &.hoursCardActive {
        background: $turquoise;
        color: $white;

        &:hover {
          opacity: 1;
          background: $btnPrimary-hover;
        }
      }
    }
  }
}
