@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.inputWrapp {
  display: grid;
  grid-gap: 30px 20px;
  grid-template-columns: repeat(3, calc(33.3333333% - 13.33333px));
}

@media screen and (max-width: 930px) {
  .inputWrapp {
    grid-template-columns: repeat(2, calc(50% - 10px));
  }
}

@media screen and (max-width: 640px) {
  .inputWrapp {
    grid-template-columns: repeat(1, 100%);
    grid-gap: 30px 0px;
  }
}
