@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  margin-top: 30px;

  .upload {
    margin-left: 0;

    :global {
      .ant-upload.ant-upload-select-picture-card {
        border-radius: initial;
      }
    }
  }

  .label {
    @include h3;
    margin: 30px 0 10px;
  }

  .announcement {
    textarea {
      min-height: 150px;
    }
  }

  .timeWrapp {
    display: flex;
    align-items: center;

    > div:first-child {
      max-width: 200px;
      margin-right: 20px;
    }

    > div:last-child {
      max-width: 110px;
    }
  }

  :global {
    .editorClassName {
      border: 1px solid $bg-grey-2;
      padding: 0 10px;
      min-height: 200px;
    }
  }
}
