@import 'assets/scss/colors.scss';

.starWrapper {
  display: flex;
  align-items: center;
  gap: 5px;

  .active {
    path {
      fill: $yellow;
    }
  }

  .inactive {
    path {
      fill: $light-yellow;
    }
  }
}
