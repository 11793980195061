@import 'assets/scss/colors.scss';

.six {
  margin-bottom: 60px;

  h2 {
    margin-bottom: 40px;
  }

  .switchWrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
  }

  .cardWrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 20px;
  }
}
