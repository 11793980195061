@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.select {
  width: 100%;

  .label {
    @include text;
    margin-bottom: 8px;

    .necessaryLabel {
      @include text;
      color: $dark-grey;
    }
  }

  :global {
    .ant-select-arrow-loading {
      color: $turquoise;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 4px;
    }

    .ant-select .ant-select-selector span.ant-select-selection-item {
      @include text;
      color: $black;
    }

    .ant-select-selection-search {
      input {
        height: 100% !important;
      }
    }

    .ant-select-selection-placeholder {
      @include text;
      color: $dark-grey;
    }

    .ant-select-selection-item {
      .ant-select-selection-item-remove {
        display: flex;
        align-items: center;
      }

      .anticon-close {
        @include icon(13px);
        @include center();
        vertical-align: initial !important;
        background-image: url(../../assets/images/common/redesign/deleteCircle.svg);
        background-repeat: no-repeat;
        background-size: contain;

        svg {
          display: none;
        }
      }
    }

    .ant-select {
      .ant-select-selector {
        height: auto;
        border: 1px solid $input-border !important;
        box-shadow: none !important;
        border-radius: 5px;
        padding: 4.9px 15px;
        min-height: 42px;
        display: flex;
        align-items: center;

        .ant-select-selection-item {
          @include text;
          color: $black;
          height: fit-content;
          line-height: 30px;
        }
      }
      .ant-select-arrow {
        width: 16px;
        height: 9px;
        top: 58%;

        &::after {
          content: '';
          background-image: url('../../assets/images/common/arrow.svg');
          background-size: contain;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
          transition: 0.4s;
        }

        span:not(.anticon-loading) {
          display: none;
        }
      }
    }
    .ant-select-open {
      .ant-select-selector {
        border: 1px solid $btn-active-color !important;
        border-radius: 5px;
      }
    }

    .ant-select-open {
      .ant-select-arrow {
        &::after {
          content: '';
          transform: rotate(180deg);
        }
      }
    }
  }
}

:global {
  .ant-select-dropdown {
    z-index: 9999 !important;

    .ant-select-item-option-content {
      @include text;
      color: $black;
    }
    .ant-select-item-option-selected {
      background-color: transparent !important;
      .ant-select-item-option-content {
        @include text;
      }
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: $active-color-light !important;
    }

    .ant-select-item-option-active {
      background-color: $active-color-light !important;
    }

    .anticon-check {
      svg {
        fill: $turquoise;
      }
    }
  }
}
