.three {
  margin-bottom: 60px;

  h2 {
    margin-bottom: 40px;
  }

  .form {
    margin-bottom: 38px;
    max-width: 420px;
    width: 100%;

    input {
      width: 100%;
    }
  }

  .map {
    height: 380px;

    .iconWrapper {
      position: relative;

      .icon {
        position: absolute;
        width: 45px;
        height: 57px;
        top: -49px;
        left: -21px;
      }
    }
  }
}
