@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.input {
  margin-top: 5px;
  width: 330px;
}

.disableInput {
  :global {
    .ant-select-selection-search {
      display: none;
    }
  }
}

.aboutText {
  textarea {
    min-height: 110px;
  }
}

@media screen and (max-width: 1350px) {
  .aboutText {
    textarea {
      min-height: 154px;
    }
  }
}

@media screen and (max-width: 767px) {
  .uploadMoveWrapp {
    margin-right: -10px;

    :global {
      .ant-upload-list-picture-card-container {
        @include icon(59px);
        margin-right: 10px;

        .ant-upload-draggable-list-item {
          @include icon(59px);
        }
      }

      .ant-upload-draggable-list-item {
        &::after {
          @include icon(16px);
          top: -6px;
          right: -6px;
        }
      }

      .ant-upload.ant-upload-select-picture-card {
        @include icon(59px);

        svg {
          @include icon(20px);
        }
      }
    }
  }
}
