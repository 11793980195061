@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.header {
  padding: 30px 40px;
  border: 1px solid $border;
  border-radius: 10px;
  position: relative;
  cursor: pointer;

  .arrow {
    position: absolute;
    right: 44px;
    transition: 0.4s;
    top: 50%;
    transform: translateY(-50%);
    svg path {
      fill: $black;
    }
  }
}

// accordion

.header.active {
  border-radius: 10px 10px 0 0;

  .arrow {
    transform: rotate(180deg) translateY(50%);
  }
}

.contentWrapp {
  transition: max-height 0.4s;
  max-height: 0;

  > div {
    transition: 0.4s;
    opacity: 0;
    padding: 30px 40px;
  }
}

.contentWrapp.open {
  visibility: initial;
  height: 100%;
  font-size: initial;

  > div {
    opacity: 1;
    height: 100%;
    border-left: 1px solid $border;
    border-right: 1px solid $border;
    border-bottom: 1px solid $border;
    border-radius: 0px 0px 10px 10px;
  }
}

@media screen and (max-width: 1023px) {
  .header {
    padding: 20px;

    .arrow {
      right: 20px;
    }
  }

  .contentWrapp {
    > div {
      padding: 20px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .header {
    .arrow {
      top: 32px;
    }
  }
}
