@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
  padding: 0 30px;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    padding: 0 20px;
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    padding: 0 15px;
  }
}
