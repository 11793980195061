@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.listWrapp {
  > div:not(:last-child) {
    margin-bottom: 15px;
  }
}

.wrapper {
  @include border($border, 5px);
  cursor: pointer;
  display: flex;
  padding: 10px 8px;
  position: relative;
  height: 52px;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.border {
  border: 1px solid $input-border;

  .name {
    @include text;
    font-weight: 600;
    color: $input-border;
  }

  svg {
    fill: $input-border;
  }
}

.activeBorder {
  border: 1px solid $btn-active-color;

  .name {
    @include text;
    font-weight: 600;
    color: $black;
  }

  svg {
    fill: $black;
  }
}

.block {
  display: flex;
  align-items: center;
}

.wrapperName {
  @include text-nowrap();
  max-width: 230px;
  margin-left: 10px;
}

.name {
  color: $text-grey;
}

@media screen and (max-width: 1350px) {
  .wrapperName {
    max-width: 150px;
  }
}

@media screen and (max-width: 767px) {
  .wrapperName {
    max-width: calc(100vw - 100px);
  }
}
