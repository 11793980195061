@import 'assets/scss/colors.scss';

.sectionThree {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  button {
    width: fit-content;

    .plusIcon {
      margin-right: 10px;

      rect {
        fill: $green;
      }

      path {
        fill: $white;
      }
    }
  }
}
