@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography-mixins.scss';

.wrapper {
  max-width: 700px;
  width: 100%;
  height: 325px;
  display: flex;

  .wrappTab {
    margin-bottom: 30px;

    p {
      @include text;
      color: $black;
      margin-bottom: 10px;
    }

    > div {
      display: flex;

      button {
        border-radius: 10px;
        width: 100px;
        padding: 0;

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  .leftContent {
    margin-right: 60px;
  }

  .centerContent {
    display: flex;
    align-items: center;
    margin: -30px 0px;
    background-color: $border;
    width: 1px;
    position: relative;

    span {
      @include center;
      @include icon(30px);
      @include border($border, 50%);
      position: absolute;
      background-color: $white;
      left: -14px;
      z-index: 10;
    }
  }

  .rigthContent {
    margin-left: 60px;
    width: 100%;

    .calendar {
      border: none;
      padding: 0 40px;
      height: 100%;
      margin-left: -40px;
      margin-right: -40px;
      padding-bottom: 15px;

      > div {
        height: 100%;
      }

      :global {
        .fc-daygrid-event {
          left: 6px;
          top: -10px;
        }
      }
    }
  }
}

.checkboxWrapp {
  p {
    margin-bottom: 16px;
  }

  label {
    margin-bottom: 10px;
    align-items: center;

    > span {
      font-weight: 400;
      &:last-child {
        margin-top: 5px;
      }
    }
  }

  :global {
    .ant-checkbox {
      margin-right: 5px;
    }

    .ant-checkbox-inner {
      @include icon(20px);

      &::after {
        height: 10px;
        top: 27%;
        left: 20%;
      }
    }
  }
}

.dateCheckbox {
  width: 100%;

  p {
    @include text;
    color: $black;
    margin-bottom: 10px;
  }

  label {
    align-items: center;

    > span:last-child {
      margin-top: 5px;
    }
  }

  span {
    font-weight: 400;
  }

  .weeks {
    padding-right: 7px;
    max-height: 280px;
    overflow-y: scroll;
    width: 100%;
  }

  :global {
    .ant-checkbox {
      margin-right: 5px;
    }

    .ant-checkbox-inner {
      @include icon(20px);

      &::after {
        height: 10px;
        top: 27%;
        left: 20%;
      }
    }
  }
}

.listCheckbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-left: -12px;
  max-height: 81px;
  overflow-y: auto;
  max-width: 700px;

  label {
    width: 32%;
    max-width: 32%;
    margin-bottom: 10px;

    span {
      font-weight: 400;
    }

    span:last-child {
      @include text-nowrap;
      max-width: 120px;
    }
  }
}

.line {
  height: 1px;
  background-color: $border;
  margin: 30px -60px;
}

.btn {
  @include center-between;
}

@media screen and (max-width: 860px) {
  .wrapper {
    .rigthContent {
      margin-left: 43px;

      .calendar {
        margin-right: 0;
      }
    }
  }

  .line {
    margin: 30px -30px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    flex-direction: column;
    max-width: 100%;
    height: auto;

    .leftContent {
      margin-right: 0;

      .wrappTab {
        > div {
          width: 100%;

          button {
            width: 100%;
          }
        }
      }
    }

    .centerContent {
      justify-content: center;
      width: auto;
      height: 1px;
      margin: 40px -15px;

      span {
        left: initial;
        transform: rotate(90deg);
      }
    }

    .rigthContent {
      margin-left: 0;

      .calendar {
        margin: 0;
        padding: 0;
        min-width: 100%;

        :global {
          .fc-scroller {
            overflow: hidden !important;
          }

          .fc-view-harness.fc-view-harness-active {
            height: 374px !important;
          }

          .fc-scrollgrid-sync-table {
            height: 350px !important;
            margin-top: 30px;
          }
        }
      }
    }
  }

  .dateCheckbox {
    .weeks {
      max-height: 219px;
    }

    label > span:last-child {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .checkboxWrapp {
    label > span:last-child {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .listCheckbox {
    margin-left: 0;
    max-height: 175px;
    max-width: 100%;

    > label {
      width: 100%;
      max-width: 100%;
      margin-left: 0 !important;

      > span:last-child {
        max-width: 90%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .btn {
    flex-direction: column;

    button {
      width: 100%;
    }

    > button:first-child {
      margin-bottom: 20px;
    }
  }

  .line {
    margin: 30px -15px;
  }
}
