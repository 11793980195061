@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.customTable {
  @include border($admin-table-border-color, 8px);
  background: $white;

  table {
    width: 100%;

    thead {
      tr {
        border-bottom: 1px solid $admin-table-border-color;

        th {
          p {
            @include desc-1;
            display: flex;
            align-items: center;
            color: $admin-table-secondary-text-color;
            background: inherit;
            padding: 30px 15px 12px;

            svg {
              margin: -5px 0 0 5px;
            }
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid $admin-table-border-color;

        &:hover {
          background: $admin-table-td-hover-color;
        }
      }

      .emptyTable {
        text-align: center !important;
        color: $admin-table-secondary-text-color;
        padding: 50px 0;
      }

      .loader {
        text-align: center !important;
        padding: 50px 0;
      }

      td {
        @include desc-1;
        padding: 12px 15px;
        color: $admin-table-text-color;
      }
    }
  }

  .pagination {
    width: 100%;
    padding: 27px 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .paginationText {
      @include desc-1;
      color: $admin-table-secondary-text-color;
      margin-right: 24px;
    }

    .paginationLeft,
    .paginationRight {
      @include icon(12px);
      cursor: pointer;

      path {
        stroke: $admin-table-secondary-text-color;
      }
    }

    .paginationLeft {
      transform: rotate(90deg);
      margin-right: 12px;
    }

    .paginationRight {
      transform: rotate(270deg);
    }
  }
}
