@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography-mixins.scss';

.wrapper {
  padding-left: 15px;

  h1 {
    margin: 10px 0px;
    font-size: 25px;
  }

  p {
    color: $grey;
  }
}

.servicesCards {
  min-height: 550px;
  padding: 0px 50px;
}

.categoriesCards {
  padding-right: 50px;
  border-right: 1px solid $light-grey;
  width: 30%;
  min-height: 360px;
}

.infoWrapp {
  display: flex;

  .avatar {
    width: 15%;
    p {
      @include text;
      margin-bottom: 8px;
      color: $black;
    }
  }

  .inputWrapp {
    width: 35%;
    margin: 0 40px;
    > div:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .texareaWrapp {
    width: 50%;
    min-height: 200px;
  }
}

.wrappColor {
  p {
    @include text;
    color: $black;
    margin-bottom: 30px;
  }
  .necessaryLabel {
    @include text;
    color: $dark-grey;
  }
}

.uploadAvatar {
  position: relative;
  margin-right: 30px;
  @include icon(160px);
  :global {
    .ant-upload {
      @include icon(160px);
      border-radius: 50%;
    }
    .ant-upload.ant-upload-select-picture-card {
      border: 1px dashed $dark-grey;
      border-radius: 50%;
    }
    .ant-upload-list-item-info {
      border-radius: 50%;
    }
    .ant-upload-list-picture-card-container {
      @include icon(160px);
    }

    .ant-upload-list-item-info {
      &::before {
        @include icon(160px);
        border-radius: 50%;
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .infoWrapp {
    .avatar {
      width: 20%;
    }
  }
}

@media screen and (max-width: 850px) {
  .infoWrapp {
    flex-wrap: wrap;
    .avatar {
      width: 30%;
      :global {
        .ant-upload-picture-card-wrapper {
          text-align: center;
        }
      }
    }
    .inputWrapp {
      width: 70%;
      margin: 0;
    }
    .texareaWrapp {
      width: 100%;
      min-height: auto;
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 720px) {
  .uploadAvatar {
    margin: 0 auto;
  }

  .infoWrapp {
    flex-wrap: wrap;

    .avatar {
      width: 100%;
    }

    .inputWrapp {
      width: 100%;
      margin: 0;
      margin-top: 30px;
    }

    .texareaWrapp {
      width: 100%;
      min-height: auto;
      margin-top: 30px;
    }
  }

  .btn {
    width: 100%;
    button {
      width: 100%;
    }
  }

  .uploadMoveWrapp {
    margin-right: -10px;

    :global {
      .ant-upload-list-picture-card-container {
        @include icon(59px);
        margin-right: 10px;

        .ant-upload-draggable-list-item {
          @include icon(59px);
        }
      }

      .ant-upload-draggable-list-item {
        &::after {
          @include icon(16px);
          top: -6px;
          right: -6px;
        }
      }

      .ant-upload.ant-upload-select-picture-card {
        @include icon(59px);

        svg {
          @include icon(20px);
        }
      }
    }
  }
}
