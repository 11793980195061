@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  @include center-between;
  padding: 20px 30px;

  .title {
    @include h1;
    white-space: nowrap;
    min-width: 150px;

    > span {
      text-transform: lowercase;
    }
  }

  .search {
    max-width: 840px;
    width: 100%;
  }

  .btn {
    display: flex;

    button {
      height: 42px;
      padding: 0 25px;
      border-radius: 5px;
    }

    button:not(:last-child) {
      margin-right: 10px;
    }
  }

  .clientBtn {
    align-items: center;
  }
}

@media screen and (max-width: 1500px) {
  .wrapper {
    .search {
      max-width: 420px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    padding: 20px;
    margin-top: 13px;
  }
}

@media screen and (max-width: 1023px) {
  .wrapper {
    .search {
      max-width: 280px;
    }
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid $light-grey;

    .search {
      max-width: 100%;
      margin: 15px 0;
    }

    .btn {
      display: flex;
      width: 100%;

      button {
        width: 100%;
      }

      button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .wrapper {
    .btn {
      flex-wrap: wrap;

      button {
        margin-right: 0px;
        margin-left: 0;
      }

      button:first-child {
        width: 49%;
        margin-left: 0 !important;
        margin-right: 0;
      }

      button:nth-child(2) {
        width: 49%;
        margin-left: auto;
        margin-right: 0;
      }

      .clientBtn {
        width: 100%;
        margin-top: 20px;
        padding-left: 0;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    margin-top: -1px;
  }
}
