@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.clientRow {
  td {
    @include text;
    vertical-align: middle !important;
  }

  .avatar {
    > div {
      display: flex;
      align-items: center;

      :global {
        .ant-avatar {
          &::before {
            width: 23px;
            margin-left: -10px;
            margin-top: -10px;
          }
        }
      }

      > p {
        @include text-nowrap();
        max-width: 250px;
        margin-left: 15px;
      }
    }
  }
}
