@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.listWrapp {
  padding: 0 20px;
  padding-bottom: 10px;
}

.wrapper {
  padding: 20px 0;
  border-bottom: 1px solid $light-grey;
  > p:not(:last-child) {
    margin-bottom: 10px;
  }

  .row:not(:last-child) {
    margin-bottom: 10px;
  }

  .avatar {
    :global {
      .ant-avatar {
        &::before {
          width: 18px;
          margin-left: -8px;
          margin-top: -8px;
        }
      }
    }
    .subTitle {
      margin-bottom: 5px;
    }

    > div {
      display: flex;

      > p {
        @include text;
        margin-left: 10px;
        max-width: 82%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    p {
      @include text;
      color: $black;
    }
  }

  .subTitle {
    @include desc-2;
    display: block;
    color: $grey;
  }
}
