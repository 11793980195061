.search {
  max-width: 840px;
  width: 100%;
  margin: 0 15px;
}

.mobileSearchWrapp {
  display: none;
  margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .searchWrapp {
    margin-left: 54px;
  }
}

@media screen and (max-width: 767px) {
  .orderHeader {
    flex-direction: column;
    align-items: flex-start !important;
    height: auto !important;
    padding: 20px !important;
  }

  .searchWrapp {
    display: none;
  }

  .mobileSearchWrapp {
    display: block;
  }
}
