@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  display: flex;
  flex-direction: column;

  :global {
    .ant-picker {
      @include border($input-border, 5px);
      height: 42px;
      padding: 4px 15px 4px;
      width: 100%;
      box-shadow: none;

      &-focused {
        @include border($btn-active-color, 5px);
      }

      &:hover {
        border: 1px solid $input-border;
      }

      input {
        @include text;

        &::placeholder {
          @include text;
        }
      }

      .ant-picker-suffix {
        svg {
          @include icon(17px);
          fill: $input-border;

          path {
            fill: $input-border;
          }
        }
      }

      .ant-picker-clear {
        display: none;
      }
    }

    .ant-picker-status-error {
      @include border($error-color, 5px);
    }
  }
}

.label {
  @include text;
  margin-bottom: 8px;

  .necessaryLabel {
    @include text;
    color: $dark-grey;
  }
}
