@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.pagination {
  width: 100%;
  padding: 27px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  user-select: none;

  .paginationText {
    @include desc-1;
    color: $admin-table-secondary-text-color;
    margin-right: 24px;
  }

  .paginationLeft,
  .paginationRight {
    @include icon(12px);
    cursor: pointer;

    path {
      stroke: $admin-table-secondary-text-color;
    }
  }

  .paginationLeft {
    transform: rotate(90deg);
    margin-right: 12px;
  }

  .paginationRight {
    transform: rotate(270deg);
  }
}
