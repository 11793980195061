@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  @include center-between;
  @include border($border, 1px);
  padding: 10px 8px;
  position: relative;
  border-radius: 4px;
  min-height: 30px;
  width: 100%;

  &.active {
    @include border($blue, 1px);
  }

  .icon {
    position: absolute;
    left: -10px;
    top: 11px;
    z-index: 1;
  }

  .name {
    margin-left: 10px;

    > div {
      color: $text-grey;
    }
  }
}
