@import 'assets/scss/colors.scss';

.layout {
  min-height: 100vh;
  height: 100%;
}

.header {
  padding: 20px;
  max-height: 88px;
  height: 100%;
  background: $light-black;

  @media screen and (max-width: 500px) {
    padding: 15px;
    max-height: 74px;
  }
}

.content {
  background: $white;
}

@media screen and (max-width: 1024px) {
  .content {
    min-height: 89vh;
  }
  .wrappMenu {
    top: 74px;
    position: fixed;
    width: 100vw;
    min-height: calc(100vh - 74px);
    height: 100%;
    z-index: 25;
  }
  .header {
    position: fixed;
    width: 100%;
    z-index: 20;
  }
  .wrappContent {
    padding-top: 75px;
  }
}

@media screen and (max-width: 767px) {
  .content {
    min-height: calc(89vh - 88px);
  }
}
