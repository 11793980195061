@import 'assets/scss/colors.scss';

.faqAnswer {
  .titleWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    width: 100%;

    h1 {
      margin: 0 auto;
    }

    .arrow {
      width: 30px;
      height: 16px;
      cursor: pointer;

      path {
        fill: $turquoise;
      }
    }
  }

  .faqTextBlock {
    margin-bottom: 30px;
  }

  .switch {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .button {
    margin-top: 30px;
  }
}
