@import './mixins.scss';

@mixin h1 {
  @include fonts(26px, 600);
  line-height: 140%;
}

@mixin h2 {
  @include fonts(22px, 600);
  line-height: 140%;
}

@mixin h3 {
  @include fonts(16px, 600);
  line-height: 140%;
}

@mixin h4 {
  @include fonts(14px, 600);
  line-height: 140%;
}

@mixin h5 {
  @include fonts(12px, 600);
  line-height: 140%;
}

@mixin h6 {
  @include fonts(12px, 600);
  line-height: 140%;
}

@mixin title {
  @include fonts();
  line-height: 140%;
}

@mixin text {
  @include fonts(16px);
  line-height: 140%;
}

@mixin desc-1 {
  @include fonts(14px);
  line-height: 140%;
}

@mixin desc-2 {
  @include fonts(12px);
  line-height: 140%;
}
