@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.fileWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  .upload {
    height: 30px;
    margin: inherit;
    max-width: 110px;
    width: 100%;

    :global {
      .ant-upload-list {
        display: none;
      }
    }
  }

  .uplodBtn {
    @include center;
    @include desc-1;
    background: $turquoise;
    color: white;
    border-radius: 23px;
    height: 30px;
    max-width: 110px;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background: $btnPrimary-hover;
    }

    input[type='file'] {
      display: none;
    }
  }

  p {
    @include desc-1;
    color: $black;
    margin-bottom: 0;
  }

  &.disabled {
    .file {
      background: $btnPrimary-disable;
    }

    p {
      color: $grey;
    }
  }
}
