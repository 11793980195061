@import 'assets/scss/mixins.scss';

.seo-page {
  h4 {
    margin-bottom: 60px;
  }

  .th-name {
    padding-left: 50px;
  }

  .seo-header {
    @include center-between;
    margin-bottom: 30px;
  }
}
