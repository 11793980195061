@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';

.wrapper {
  margin-right: -30px;
  :global {
    .active-move {
      border: 2px solid $turquoise;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    .ant-upload-list-picture-card-container {
      @include icon(198px);
      margin-bottom: 20px;
    }

    .ant-upload-list-picture-card-container {
      margin-right: 20px;
    }

    .ant-upload.ant-upload-select-picture-card {
      @include icon(198px);
      border: 1px dashed $dark-grey;

      &:hover {
        border: 1px dashed $dark-grey;
      }
    }

    .ant-upload-list-picture-card-container .ant-upload-list-item {
      border: none;
      border-radius: 5px;
      padding: 0;
    }

    .ant-upload-draggable-list-item {
      @include icon(198px);
      border-radius: 5px;
      position: relative;

      &::after {
        content: '';
        display: block;
        background-image: url('../../assets/images/common/move.svg');
        background-size: contain;
        width: 24px;
        height: 24px;
        position: absolute;
        z-index: 5;
        top: -12px;
        right: -13px;
      }
    }

    .ant-upload-list-picture-card-container .ant-upload-list-item-thumbnail {
      img {
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }
}
