@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  margin-bottom: 50px;
}

.btn {
  padding: 9.5px 60px;
  border: 1px solid $border;
  width: fit-content;
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    @include icon(33px);
  }

  p {
    margin-left: 10px;
    font-weight: 600;
  }
}

.googleInfo {
  margin-top: 15px;
}

@media screen and (max-width: 500px) {
  .btn {
    width: 100%;
    padding: 9.5px 0;
    justify-content: center;
  }
}
