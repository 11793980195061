@import 'assets/scss/typography.scss';

.feedbackRow {
  @include desc-1;
  cursor: pointer;

  p {
    @include desc-1;
  }

  .message,
  .answer {
    max-width: 590px;
  }

  .notification {
    @include center;
    padding: 2px 10px;
    color: $turquoise-bold;
    background-color: $turquoise-light;
    border-radius: 6px;
  }
}
