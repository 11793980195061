.search {
  max-width: 840px;
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .search {
    max-width: 420px;
  }
}

@media screen and (max-width: 1024px) {
  .search {
    max-width: 280px;
  }

  .feedbackTable {
    table tr th:nth-child(2) {
      width: 200px;
    }
  }
}

@media screen and (max-width: 768px) {
  .feedbackHeader {
    flex-direction: column;
    height: auto !important;
    padding-bottom: 20px !important;

    > div:first-child {
      width: 100%;
      text-align: left;
      margin-top: 10px;
    }
  }

  .feedbackPage {
    padding: 0 20px 14px;
  }

  .search {
    max-width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .feedbackHeader {
    .feedbackHeaderTitle {
      margin-bottom: 15px;
    }

    .feedbackHeaderSort {
      margin-top: 20px;
    }
  }
}
