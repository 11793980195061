@import 'assets/scss/mixins.scss';

.blogWrapper {
  .titleWrapper {
    @include center-between;
    margin-bottom: 60px;

    .titleActions {
      @include center;
      gap: 30px;
    }
  }

  .table {
    tr {
      cursor: pointer;
    }
    tr th:nth-child(2) p,
    tr:first-child {
      cursor: initial;
    }

    tr th p {
      width: fit-content;
      cursor: pointer;
    }
  }
}
