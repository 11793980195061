@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.five {
  margin-bottom: 60px;

  h2 {
    margin-bottom: 40px;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    .dayWrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      & > p {
        margin-bottom: 0;
        min-width: 100px;
      }

      .work {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-right: 30px;
      }

      .break {
        display: flex;
        align-items: center;
        gap: 20px;

        p {
          margin-bottom: 0;
          margin-right: 10px;
        }
      }

      span {
        @include text;
        @include center;
        @include border($grey, 4px);
        display: block;
        color: $grey;
        padding: 14px 48px;
      }
    }
  }
}
