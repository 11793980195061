@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  width: 100%;

  .label {
    @include text;
    margin-bottom: 8px;

    .necessaryLabel {
      @include text;
      color: $dark-grey;
    }
  }

  .input {
    position: relative;
    display: flex;

    span {
      @include text;
      border-top: 1px solid $input-border;
      border-bottom: 1px solid $input-border;
      border-left: 1px solid $input-border;
      border-radius: 5px 0px 0px 5px;
      display: flex;
      align-items: center;
      padding-left: 15px;
      padding-top: 2px;
    }

    .border {
      border-top: 1px solid $btn-active-color;
      border-bottom: 1px solid $btn-active-color;
      border-left: 1px solid $btn-active-color;
    }

    .disabled {
      background: $bg-grey-2;
      border-color: $border;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  input {
    @include text;
    border-top: 1px solid $input-border;
    border-bottom: 1px solid $input-border;
    border-right: 1px solid $input-border;
    border-left: none;
    border-radius: 0px 5px 5px 0px;
    height: 42px;
    width: 100%;
    outline: none;
    padding: 0;
    padding-top: 2px;

    &:focus {
      border-top: 1px solid $btn-active-color;
      border-bottom: 1px solid $btn-active-color;
      border-right: 1px solid $btn-active-color;
    }

    .disabled {
      background: $bg-grey-2;
      border-color: $border;
      color: rgba(0, 0, 0, 0.5);

      &:focus {
        border-color: $border;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .errorText {
    @include desc-1;
    position: absolute;
    color: $error-color;
    display: flex;
    align-items: center;
    margin-top: 45px;

    svg {
      margin-right: 7px;
      margin-top: -3px;
    }
  }
}
