@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.calendar {
  position: relative;
  flex-grow: 1;
  margin: 0px 0px 50px 20px;

  &.calendarDay {
    height: 3576px;
    overflow-x: hidden;

    :global {
      .fc-timeGridDay-view .fc-scroller.fc-scroller-liquid-absolute {
        overflow: hidden !important;
        position: initial !important;
      }

      .fc-media-screen {
        max-height: initial !important;
      }
      .fc-view-harness.fc-view-harness-active {
        height: 100% !important;
      }
    }

    .time-now-wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      border: 2px solid $blue;
      background-color: $white;
      height: 20px;
      left: 2px;
      top: 200px;
      z-index: 10;
      padding: 3px 5px 2px 5px;
      border-radius: 15px;
      font-size: 0.8em;
      color: $blue;

      &::before {
        content: '';
        position: absolute;
        width: calc(100vw - 159px);
        left: 45px;
        height: 2px;
        background: $blue;
        z-index: 10;
        box-shadow: 0 0 10px rgb(0 0 0 / 50%);
      }
    }
  }

  .timeMobile {
    display: none;
    font-size: 1em;
    position: sticky;
    padding-right: 6px;
    left: 0;
    height: max-content;
    z-index: 2;
    background: $white;
  }

  .weekTitle {
    @include h2;
    color: $grey;
    font-weight: 400;
    transition: 0.4s;
    text-decoration: none;
    text-transform: capitalize;

    span {
      @include h1;
      font-weight: 400;
      text-decoration: none;
    }

    &:hover {
      color: $turquoise;
    }
  }

  .title {
    @include desc-1;
    color: $black;
    white-space: nowrap;
    padding-left: 5px;
    text-overflow: ellipsis;
    overflow: hidden;

    span {
      @include h4;
      color: $black;
    }
  }

  .wrappEvent {
    width: 100%;
    border-radius: 5px;
    padding: 0;
    overflow: hidden;

    :global {
      .popover {
        display: none;
      }
    }
  }

  :global {
    thead .fc-col-header-cell-cushion {
      text-decoration: none !important;
    }

    .fc-media-screen {
      max-height: 82vh;
    }

    .fc.fc-media-screen.fc-direction-ltr {
      height: 100%;
    }

    .fc-daygrid-more-link {
      @include desc-1;
      color: $black;
    }

    .fc-timegrid-event.fc-v-event.fc-event {
      box-shadow: none !important;
    }

    .notActive {
      background: repeating-linear-gradient(
        -45deg,
        #f9f9f9 0 13px,
        #efefef 8px 17px
      );

      .fc-event-main {
        > div {
          display: none;
        }
      }

      margin-left: -1px !important;
      margin-right: -1px !important;
      opacity: 0.7;
    }

    .fc-header-toolbar {
      margin-bottom: 0 !important;
    }

    .fc-timeGridDay-view {
      background-color: $white;

      .fc-timegrid-event-harness.fc-timegrid-event-harness-inset {
        width: 100%;
      }

      .fc-timegrid-body {
        .fc-timegrid-slots {
          background-color: $white;
        }
      }

      .fc-scrollgrid-section-header {
        display: none;
      }

      .fc-daygrid-day-events {
        display: none;
      }

      table tr:nth-child(2) {
        display: none;
      }

      table tbody tr:first-child .fc-scroller-harness {
        display: none;
      }

      table tbody tr:first-child td {
        border-top: none;
        border-bottom: none;
      }

      .fc-timegrid-slot.fc-timegrid-slot-label {
        border: none;

        > div {
          text-align: left;
        }
      }

      .fc-scrollgrid.fc-scrollgrid-liquid {
        border-left: none;
        border-top: none;
      }

      .fc-timegrid-slot.fc-timegrid-slot-lane {
        border-left: none;
        width: 99%;
      }
    }

    .fc-timeGridWeek-view,
    .fc-timeGridDay-view {
      .fc-timegrid-event-harness-inset .fc-timegrid-event.fc-v-event {
        background-color: $active-color-light;
        border: none;
        margin: 1px 2px 0px 2px;

        .fc-event-time {
          @include desc-1;
          color: $btnPrimary-hover;
          padding: 2px 10px 0 10px;

          &::after {
            display: none;
          }
        }
      }

      .fc-highlight {
        background-color: initial;
      }
    }

    .fc-timeGridWeek-view {
      .fc-event-main {
        > div p:nth-child(2) {
          @include text-nowrap();

          span {
            padding-left: 2px;
          }
        }
      }

      > table tr th {
        border-right: none;

        > .fc-scroller-harness {
          padding: 20px 0px;
          margin-left: -20px;
          padding-left: 20px;
          border-right: none;

          .fc-scroller {
            overflow: initial !important;
          }
        }
      }

      table .fc-scrollgrid-sync-table {
        display: none;
      }

      .fc-scrollgrid {
        .fc-timegrid-divider.fc-theme-bootstrap5-shaded {
          display: none;
        }
      }

      table tr:nth-child(2) {
        display: none;
      }

      table tbody:nth-child(2) tr:first-child .fc-scroller-harness {
        display: none;
      }

      table tbody tr:first-child td {
        border-top: none;
        border-bottom: none;

        &:first-child,
        &:nth-child(2) {
          border: none;
        }
      }

      table table thead tr th {
        border: none;
      }

      .fc-scrollgrid.fc-scrollgrid-liquid {
        border-left: none;
        border-top: none;
        border: none;
      }

      .fc-timegrid-slot.fc-timegrid-slot-label {
        border: none;
      }

      .fc-timegrid-slot.fc-timegrid-slot-lane {
        border-left: none;
        width: 99%;
      }

      .fc-timegrid-col-frame {
        background-color: $white;
      }
    }

    .fc-dayGridMonth-view {
      .fc-daygrid-day-number {
        position: initial;
      }

      .fc-col-header-cell-cushion {
        text-transform: capitalize;
      }

      .fc-daygrid-day.fc-day-disabled {
        border: none;
      }

      .disableDate {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: repeating-linear-gradient(
          -45deg,
          #f9f9f9 0 15px,
          #efefef 10px 20px
        );

        span {
          display: block;
          text-align: end;
          padding: 10px;
        }
      }

      .fc-daygrid-day-events {
        top: 0;
        bottom: 0;
        position: initial;

        > div:not(:last-child, .fc-daygrid-event-harness-abs) {
          position: initial;
        }
      }

      .notActive {
        display: none !important;
      }

      .monthNotActive {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 1px;
        left: 1px;
        display: block !important;

        p {
          visibility: hidden;
        }
      }

      .fc-daygrid-event {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > div {
          padding: 2px 3px;
        }
      }

      > table table tr th {
        border: none;

        > .fc-scrollgrid-sync-inner {
          padding: 20px 0px;
          margin-left: -20px;
          padding-left: 20px;
          border-bottom: 1px solid $light-grey;
          border-right: none;

          .fc-scroller {
            overflow: initial !important;
          }

          .fc-col-header-cell-cushion {
            @include h2;
            color: $grey;
            font-weight: 400;
          }
        }
      }

      .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
        background-color: $white;
      }

      .fc-day-other {
        .fc-highlight {
          background-color: rgba(219, 246, 246, 0.6);
        }
      }

      .fc-daygrid-day {
        height: 205px;

        .fc-daygrid-day-number {
          @include h3;
          padding: 10px;
          text-decoration: none;

          &:hover {
            color: $turquoise;
          }
        }
      }

      .fc-day-today {
        .fc-daygrid-day-number {
          color: $turquoise;
        }
      }

      .fc-highlight {
        background-color: $active-color-light;
      }
    }

    .fc-timegrid-axis.fc-scrollgrid-shrink {
      display: none;
    }

    .fc-event-time {
      color: $black;
    }

    .fc-event-time {
      @include desc-1;
      color: $black !important;
      padding: 0 !important;
    }

    .fc-event-main {
      padding: 0;
    }

    .month {
      @include desc-2;

      span {
        @include h5;
      }
    }

    .fc-direction-ltr .fc-timegrid-col-events {
      margin: 1px 2px;
    }

    .fc-popover {
      @include border($light-grey, 5px);

      .fc-popover-header {
        padding: 20px 20px 15px 20px;
        background-color: $white;
        border: none;

        .fc-popover-title {
          @include h3;
        }
      }

      .fc-popover-body {
        max-height: 250px;
        overflow-y: auto;
        padding: 0px 20px 0 0;
        margin: 0 20px;
        margin-bottom: 20px;

        .notActive {
          display: none;
        }

        .popover {
          display: flex;
          width: 100%;
          height: fit-content;
          border: none;

          .popoverDate {
            @include desc-2;
            display: flex;
            flex-direction: column;
            color: $black;

            span {
              @include desc-2;
              color: $black;
            }
          }

          .popoverBg {
            width: 3px;
            margin: 0 10px;
            border-radius: 5px;
          }

          .popoverTitle {
            p {
              @include text-nowrap();
              max-width: 127px;
            }

            p:first-child {
              @include h5;
            }

            p:last-child {
              @include desc-2;
            }
          }
        }

        .fc-daygrid-event {
          box-shadow: none;

          &:hover,
          &:focus {
            background-color: transparent;
          }

          > div {
            height: fit-content;
            background-color: $white !important;
            margin-bottom: 5px;

            > p {
              display: none;
            }
          }
        }
      }
    }
  }
}

.calendarMonth {
  margin: 0;
}

@media screen and (max-width: 1300px) {
  .calendarWeekMobile {
    display: flex;
    overflow: auto;
    margin-left: 0;

    :global {
      .fc.fc-media-screen.fc-direction-ltr.fc-theme-bootstrap5 {
        width: 100%;
      }

      .fc-scrollgrid.fc-scrollgrid-liquid {
        .fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink {
          position: absolute;
          left: -2000px;
          width: 0;
          height: 0;
          max-width: 0;
          font-size: 0;
        }
      }

      .fc-scroller.fc-scroller-liquid-absolute {
        overflow: initial !important;
        position: initial;
      }
    }

    .timeMobile {
      display: block;
      width: fit-content;
      padding-top: 82px;
      padding-left: 20px;

      > div:first-child {
        margin-bottom: 101px;
      }

      > div {
        margin-bottom: 126px;
      }
    }
  }

  .calendar {
    :global {
      .fc-timeGridWeek-view {
        width: 100%;

        > table {
          width: 2154px !important;
        }

        table thead .fc-col-header-cell.fc-day {
          width: 300px;
        }

        table tbody .fc-timegrid-col.fc-day {
          width: 300px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .calendar.calendarDay {
    overflow-x: auto;

    .time-now-wrapper {
      left: 0px;

      &::before {
        width: calc(100vw - 71px);
      }
    }
  }

  .calendar {
    margin: 0px 0px 0px 20px;
  }

  .calendarMonth {
    margin: 0px 0px 3px 0px;
  }

  .calendarDayMobile {
    display: flex;
    overflow-x: auto;
    margin-top: 3px;

    :global {
      .fc.fc-media-screen.fc-direction-ltr.fc-theme-bootstrap5 {
        width: 100%;
      }

      .fc-scroller.fc-scroller-liquid-absolute {
        overflow: initial !important;

        table tr td:first-child {
          display: none;
        }
      }
    }

    .timeMobile {
      display: block;
      margin-top: 1px;

      > div:first-child {
        margin-bottom: 101px;
      }

      > div {
        margin-bottom: 126px;
      }
    }
  }

  .calendar {
    height: 100%;
    // max-height: calc(100vh - 220px);

    :global {
      .fc-timeGridDay-view {
        width: 100%;

        > table {
          width: 1500px;
        }
      }

      .fc-timeGridWeek-view {
        height: calc(100vh - 240px);
      }

      .fc-dayGridMonth-view {
        .fc-scroller table thead tr .fc-col-header-cell {
          .fc-col-header-cell-cushion {
            @include h2;
            font-weight: 400;
          }
        }

        .fc-daygrid-day-events {
          max-height: 100%;
          height: 100%;
        }

        .fc-daygrid-day .fc-day {
          max-height: 42px;
          height: 42px;
        }

        .fc .fc-scroller-harness-liquid {
          max-height: 300px;
          height: 100%;
        }

        .fc-highlight {
          background-color: transparent !important;
        }
      }
    }
  }

  .calendarMonth {
    max-height: 380px;
  }
}

@media screen and (max-width: 768px) {
  .calendarMonth {
    :global {
      .activMobileDate {
        position: relative;

        &::before {
          content: '';
          border: 1px solid $turquoise;
          border-radius: 44%;
          position: absolute;
          width: 48px;
          height: 42px;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          transform: translate(0%, -23%);
          z-index: 10;
        }
      }
    }
  }

  .calendar {
    :global {
      .fc-timeGridWeek-view {
        width: 100%;

        > table {
          width: 1803px;
        }

        table thead .fc-col-header-cell.fc-day {
          width: 250px;
        }

        table tbody .fc-timegrid-col.fc-day {
          width: 250px;
        }
      }

      .fc-dayGridMonth-view {
        .fc-daygrid-day-events {
          position: absolute;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .calendar {
    max-height: 350px;
    height: 350px;

    &.calendarDay {
      height: 100%;
      max-height: 100%;
    }

    :global {
      .fc-dayGridMonth-view {
        th .fc-scrollgrid-sync-inner {
          padding-bottom: 0 !important;
        }

        .fc-daygrid-day-events {
          > div:not(:last-child, .fc-daygrid-event-harness-abs) {
            pointer-events: none;
          }
        }

        table tbody tr,
        td {
          border: none;
        }

        > table > tbody > tr:first-child > td:first-child .fc-scroller {
          padding-top: 15px;
          overflow: initial !important;
        }

        thead tr,
        th,
        .fc-scrollgrid-sync-inner {
          border: none !important;
        }

        .fc-day-other {
          .fc-daygrid-day-number {
            color: $black;

            span {
              color: $black;
            }
          }
        }

        .fc-highlight {
          position: relative;
          display: block !important;
          height: 100%;
          background-color: transparent;

          &:after {
            content: '';
            @include border($turquoise, 44%);
            position: absolute;
            width: 48px;
            height: 42px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            transform: translate(-0%, -23%);
          }
        }

        .fc-scrollgrid-sync-table tbody tr td {
          height: 42px;
          width: 48px;
        }

        .fc-daygrid-day-top {
          justify-content: center;
          pointer-events: none;

          .fc-daygrid-day-number {
            font-weight: 400 !important;
          }

          .fc-daygrid-day-number {
            padding: 0;
          }
        }

        .fc-daygrid-day-events {
          padding-top: 22px;

          .fc-daygrid-event-harness {
            display: flex;
            justify-content: center;
          }

          > .fc-daygrid-event-harness:not(:first-child) {
            display: none;
          }

          .fc-daygrid-event {
            margin-top: -3px;

            > div {
              @include icon(4px);
              border-radius: 50%;
              padding: 0;
              background-color: $turquoise !important;
            }

            p {
              font-size: 0;
            }
          }
        }
      }
    }
  }

  .calendarMonth {
    max-height: 353px;
    height: 353px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 500px) {
  .calendar {
    :global {
      .fc-dayGridMonth-view {
        .fc-scroller table thead tr .fc-col-header-cell {
          .fc-col-header-cell-cushion {
            @include desc-1;
          }
        }

        .fc-scrollgrid-sync-table tbody tr td {
          height: 42px;
          width: 42px;
        }
      }
    }
  }
}
