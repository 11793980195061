@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.header {
  display: flex;
  align-items: center;
  padding: 0 30px;
  border-bottom: 1px solid $light-grey;

  .tabs {
    display: flex;
    align-items: center;

    p:not(:last-child) {
      margin-right: 60px;
    }

    p {
      @include h1;
      color: $dark-grey;
      padding: 20px 0px;
      border-bottom: 3px solid transparent;
      cursor: pointer;
    }

    p.active {
      color: $black;
      border-bottom: 3px solid $turquoise;
      -webkit-border-radius: 2px;
      border-radius: 2px;
    }
  }

  .btn {
    margin-left: auto;
  }
}

@media screen and (max-width: 1350px) {
  .header {
    padding: 0 20px;
  }
}

@media screen and (max-width: 500px) {
  .header {
    padding: 0 15px;

    .tabs {
      p:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  .staffHeader {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: none;

    .tabs {
      width: 100vw;
      border-bottom: 1px solid $light-grey;
      margin: 0 -15px;
      padding: 0 15px;
    }

    .btn {
      margin-left: initial;
      margin-top: 30px;

      button {
        padding-left: 0;
      }
    }
  }
}
