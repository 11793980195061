@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 14px 0;

  .buttonWrapper {
    display: flex;
    gap: 10px;

    button {
      border-radius: 6px;
      padding: 10px 25px;
      height: 42px;
    }
  }

  .input {
    position: relative;
    max-width: 370px;
    width: 100%;

    &::before {
      @include icon(22px);
      content: '';
      display: block;
      position: absolute;
      background-image: url('../../assets/images/common/search.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      top: 10px;
      right: 15px;
      z-index: 2;
    }

    input {
      @include text;
      width: 100%;
      padding: 14px 50px 14px 16px;
    }
  }
}
