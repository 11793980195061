@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.pickerWrapper {
  margin-top: 20px;
  display: flex;
  align-items: flex-end;

  .repeat {
    margin: 0 20px;
  }
}

.timepickWrapp {
  display: flex;
  align-items: center;
  margin-left: 20px;

  .dash {
    width: 6px;
    height: 2px;
    background-color: $turquoise;
    margin: 6px 15px 0 15px;
  }

  :global {
    .ant-picker {
      max-width: 110px;
      min-width: 110px;
    }
  }
}

.repeatWrapp {
  display: flex;
  align-items: flex-end;
  label {
    margin: 0 15px 6px 20px;
  }
}

@media screen and (max-width: 850px) {
  .pickerWrapper {
    flex-wrap: wrap;
    max-width: 585px;
  }
  .repeatPicker {
    margin-top: 20px;
  }
}

@media screen and (max-width: 650px) {
  .pickerWrapper {
    > div:first-child {
      width: 100%;
    }
  }
  .wrapper {
    :global {
      .ant-picker {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
  .timepickWrapp {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
    > div {
      width: 70%;
    }
  }
  .repeatPicker {
    width: 100%;
  }
  .repeat {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
}
