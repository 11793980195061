@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.checkboxWrapp {
  display: grid;
  grid-template-columns: minmax(auto, 300px) 70px 1fr 1fr;
  padding-right: 10px;
}

.header {
  width: 100%;

  > span {
    @include h2;
    display: block;
    margin-bottom: 15px;
  }

  > div {
    width: 100%;
    height: 100%;
  }
}

.wrapper {
  width: 800px;
}

.contentWrapp {
  display: flex;

  .categories {
    width: 43%;
    border-right: 1px solid $border;
    padding-right: 50px;
    margin: -30px 0 -30px 0;
    padding-top: 30px;
    max-height: 432px;
    min-height: 432px;
    overflow-y: auto;

    h2 {
      color: $black;
      margin-bottom: 30px;
    }

    .wrapperCategory {
      @include border($border, 5px);
      @include center-between();
      cursor: pointer;
      padding: 10px 20px;
      position: relative;
      height: 52px;
      max-width: 300px;
      width: 100%;
      margin-bottom: 15px;

      .categoryName {
        @include text-nowrap();
        max-width: 225px;
      }

      svg {
        fill: $input-border;
      }
    }

    .activeBorder {
      border: 1px solid $btn-active-color;

      svg {
        fill: $black;
      }

      .categoryName {
        @include text;
        font-weight: 600;
        color: $black;
      }
    }
  }

  .services {
    width: 57%;
    padding-left: 50px;
    padding-right: 7px;
    max-height: 372px;
    overflow-y: auto;

    .checkboxTitle {
      @include center-between();
      margin-bottom: 30px;

      h2 {
        color: $black;
      }
    }
  }
  > div {
    label span:last-child {
      @include text-nowrap;
      max-width: 187px;
    }
  }
  .btn {
    button:first-child {
      p {
        text-transform: uppercase;
      }
    }
  }
}

.line {
  background-color: $border;
  height: 1px;
  margin: 30px -60px 30px -60px;
}

.btn {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 950px) {
  .wrapper {
    width: auto;
  }
  .contentWrapp {
    > div label span:last-child {
      max-width: 162px;
    }

    .categories {
      width: auto;
      min-height: 380px;

      .wrapperCategory {
        max-width: 200px;
        min-width: 200px;
        margin-right: 0;

        .categoryName {
          @include text-nowrap();
          max-width: 127px;
        }
      }
    }
    .services {
      padding-left: 40px;

      .checkboxTitle {
        align-items: baseline;
      }

      > div {
        label span:last-child {
          max-width: 110px;
        }
      }
    }
  }

  .line {
    margin: 30px -30px 30px -30px;
  }
}

@media screen and (max-width: 767px) {
  .contentWrapp {
    flex-direction: column;

    .categories {
      padding-right: 0;
      border-right: none;
      border-bottom: 1px solid $border;
      min-height: fit-content;
      padding-bottom: 25px;
      margin: 0 -15px;
      padding: 0 15px 25px 15px;

      .wrapperCategory {
        max-width: 100%;

        > div {
          width: 100%;
        }

        .categoryName {
          max-width: 97%;
        }
      }
    }
    .services {
      margin-top: 30px;
      width: 100%;
      padding-left: 0px;
      padding-right: 7px;

      > div {
        label {
          // width: 100%;
          span:last-child {
            padding-left: 0;
            padding-right: 0;
            max-width: 200px;
          }
        }
      }
    }
  }

  .btn {
    flex-direction: column;

    button {
      p {
        text-transform: inherit;
      }
    }

    button:first-child {
      margin-bottom: 20px;
    }
  }
  .mobileCheckboxWrapp {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 15px;

    > time {
      width: 100%;
      margin-right: 0;
      order: 4;
    }

    > div {
      width: 80%;
      order: 1;
    }

    b:nth-child(3) {
      order: 2;
      margin-left: auto;
    }

    b:nth-child(4) {
      order: 3;
    }
  }

  .checkboxWrapp {
    display: flex;
    flex-direction: column;
  }

  .header {
    h2 {
      @include text-nowrap();
      max-width: 70%;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 15px;
    }
  }

  .line {
    margin: 30px -15px 30px -15px;
  }
}
