@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  border-radius: 1px solid $light-grey;
  width: 50%;
  min-height: 100%;

  > h2 {
    margin-bottom: 15px;
  }
}

.uploadBtn {
  background-color: $white;
  cursor: pointer;
  height: 64px;
}

.wrapper_upload {
  height: 72px;
  transition: none !important;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 30px;

  .uploadAvatar {
    @include icon(64px);
    margin: initial;
  }

  .uploadName {
    border-radius: 8px;
    min-height: 45px;
    width: 265px;
  }

  :global {
    .ant-upload.ant-upload-select-picture-card {
      height: 64px;
      width: 64px;
      background-color: $white;
      border: none;
    }
    .ant-upload-picture-card-wrapper {
      width: fit-content;
    }
    .ant-upload-list-picture-card-container {
      height: 55px;
      width: 55px;
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      padding: 0;
      border-radius: 50%;
    }
    .ant-upload-list-picture .ant-upload-list-item-error,
    .ant-upload-list-picture-card .ant-upload-list-item-error {
      border-color: transparent;
    }
    .ant-upload-list {
      height: 64px;
    }
    div,
    span,
    img {
      transition: none !important;
    }
    .ant-tooltip-content {
      display: none;
    }
  }
}
