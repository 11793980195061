$white: #fff;
$black: #000;
$grey: #949494;
$light-grey: #e0e0e0;
$text-grey: #a9aeb6;
$bg-grey: #f8f8f8;
$bg-grey-2: #f5f5f5;
$bg-grey-3: #efefef;
$bg-light-grey: #f9f9f9;
$bg-dark-grey: #2e333a;
$dark-grey: #a4a4a4;
$error-color: #ff0d39;
$primary-color: #6b75a8;
$blue: #1890ff;
$violet: #36247d;
$orange: #e69629;
$green: #00a32e;

$alert-orange: #ffc107;

$main-beauty-business-bg-color: #fafafa;
$arrow-bg: $bg-grey-2;

$calendar-header-text-color: #686868;
$calendar-dark-grey-text-color: #333333;
$calendar-event-link-color: #f2f2f2;

$light-black: #191a1b;
$input-border: $dark-grey;
$border: #c6cdcd;
$turquoise-light: #dbf6f6;
$turquoise-bold: #2a9595;
$turquoise: #0cc1c1;
$yellow: #ffab08;
$light-yellow: #ffe3ae;
$active-color-light: #dbf6f6;
$btn-hover-color: #3b3b3b;
$btnPrimary-hover: #2a9595;
$btnPrimaryRed-hover: #d81135;
$btnPrimary-disable: $light-grey;
$btn-hover-light: $active-color-light;
$btn-active-color: $turquoise;

$switch-color: #bfbfbf;

$admin-table-border-color: #dfe0eb;
$admin-table-text-color: #252733;
$admin-table-secondary-text-color: #9fa2b4;
$admin-table-td-hover-color: #dbf6f6;
