@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';

.passwordWrapp {
  max-width: 680px;

  > div {
    margin-bottom: 30px;
  }

  .input > div {
    &:first-child {
      color: $dark-grey;
    }
  }
}
