@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography-mixins.scss';

.wrapper {
  width: 100%;

  .label {
    @include text;
    position: initial;
    margin-bottom: 8px;
    color: $black;

    .necessaryLabel {
      @include text;
      color: $dark-grey;
    }
  }

  :global {
    .ant-picker {
      @include border($input-border, 5px);
      height: 42px;
      padding: 4px 15px 4px;
      width: 100%;
      box-shadow: none;

      &-focused {
        @include border($btn-active-color, 5px);
      }

      &:hover {
        border: 1px solid $input-border;
      }

      input {
        @include text;
        &::placeholder {
          @include text;
        }
      }
      .ant-picker-suffix {
        svg {
          @include icon(17px);
          fill: $input-border;

          path {
            fill: $input-border;
          }
        }
      }

      .ant-picker-clear {
        display: none;
      }
    }
    .ant-picker-status-error {
      @include border($error-color, 5px);
    }
  }
}

:global {
  .popupCalendar {
    @include border($border, 8px);
    box-shadow: none;
    z-index: 9999 !important;

    .ant-picker-panel-container {
      border-radius: 8px;

      .ant-picker-header-view {
        button {
          @include desc-1;
          font-weight: 600;
        }
      }

      .ant-picker-header {
        padding: 4px 15px;
        border-bottom: none;
      }

      .ant-picker-body {
        padding: 5px 15px 15px 15px;
      }

      .ant-picker-cell .ant-picker-cell-inner {
        color: $calendar-header-text-color;

        &:hover {
          background: transparent !important;
        }
      }

      .ant-picker-cell-in-view {
        .ant-picker-cell-inner {
          @include desc-1;
          color: $black;
        }
      }

      .ant-picker-cell-today {
        .ant-picker-cell-inner {
          color: $turquoise;

          &::before {
            border: none;
          }
        }
      }

      .ant-picker-cell-selected {
        .ant-picker-cell-inner {
          background: none;

          &::before {
            content: '';
            position: absolute;
            @include border($turquoise, 45%);
            width: 36px;
            height: 30px;
            top: -6px;
            left: -5px;
          }
        }
      }

      .ant-picker-content thead tr th {
        @include desc-1;
        color: $calendar-header-text-color;
      }

      .ant-picker-footer {
        padding: 15px;

        .ant-picker-today-btn {
          @include text;
          @include center;
          color: $white;
          background-color: $turquoise;
          width: 100%;
          height: 34px;
          border-radius: 25px;

          &:hover {
            background-color: $btnPrimary-hover;
          }
        }
      }

      .ant-picker-week-panel tr th:first-child {
        display: none;
      }

      .ant-picker-cell-week {
        display: none;
      }

      .ant-picker-week-panel {
        .ant-picker-cell-today,
        .ant-picker-cell-selected {
          .ant-picker-cell-inner {
            &:before {
              display: none;
            }
          }
        }

        .ant-picker-week-panel-row-selected {
          margin-right: -15px;
          position: relative;

          td {
            padding-bottom: 0;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              border-top: 1px solid $turquoise;
              border-bottom: 1px solid $turquoise;
            }
          }

          td:nth-child(2) {
            &::after {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              border-radius: 15px 0 0 15px;
              border-left: 1px solid $turquoise;
              border-bottom: 1px solid $turquoise;
              border-top: 1px solid $turquoise;
            }
          }

          td:last-child {
            &::after {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              border-radius: 0 15px 15px 0;
              border-top: 1px solid $turquoise;
              border-right: 1px solid $turquoise;
              border-bottom: 1px solid $turquoise;
            }
          }

          td {
            background-color: transparent;
          }
        }
      }

      .ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
      .ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
        color: $black;
      }

      .ant-picker-cell::before {
        top: 13px;
      }
    }
  }
}
