@import 'assets/scss/colors.scss';
@import 'assets/scss/typography.scss';

.custom-search {
  position: relative;
  padding-bottom: 16px;

  label {
    @include text;
    cursor: pointer;
    margin-bottom: 8px;
  }

  .custom-search__input {
    :global {
      .ant-input-affix-wrapper {
        @include text;
        padding: 0 28px 0 12px;
        color: rgba(0, 0, 0, 0.85);
        width: 100%;

        .ant-input-suffix {
          div {
            padding-left: 6px;
          }
        }

        input::placeholder {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    &.custom-search__input_selected {
      :global {
        .ant-input-affix-wrapper {
          input::placeholder {
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
  }

  .custom-search__popup {
    background: $white;
    position: absolute;
    left: 0;
    width: 100%;
    min-height: 50px;
    max-height: 190px;
    overflow: auto;
    z-index: 4;
    border: 1px solid $light-grey;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    .custom-search__popup__list {
      list-style-type: none;
      padding: 15px 0;
      margin: 0;

      .custom-search__popup__list-item {
        padding: 5px 14px;
        cursor: pointer;

        &:hover {
          background: $active-color-light;

          .custom-search__popup__list-item__text {
            &::after {
              background: linear-gradient(
                270deg,
                $active-color-light 35%,
                rgba(255, 255, 255, 0) 93.97%
              );
            }
          }
        }

        .custom-search__popup__list-item__text {
          overflow: hidden;
          position: relative;

          &:has(.custom-search__popup__list-item__text__in-fron-of) {
            @include center-between;
          }

          span:first-child {
            @include text-nowrap;
            @include text;
            color: $black;
          }

          .custom-search__popup__list-item__text__in-fron-of {
            z-index: 1;
            color: $black;
            font-weight: 700;
          }

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 100px;
            height: 22px;
            background: linear-gradient(
              270deg,
              $white 35%,
              rgba(255, 255, 255, 0) 93.97%
            );
          }
        }

        span {
          @include desc-1;
          color: $dark-grey;
        }
      }
    }
  }
}
