@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.item {
  margin-bottom: 30px;
}

.switchWrap {
  display: flex;
  align-items: center;

  span {
    @include h2;
    margin-left: 20px;
  }
}

.timePicker {
  max-width: 110px;
}

.description {
  @include desc-1;
  color: $text-grey;
  margin: 10px 0px;
  max-width: 650px;
}

.subDescription {
  margin-bottom: 10px;
}

.url {
  @include desc-2;
  color: $black;
  margin-top: 20px;

  svg {
    @include icon(11px);
    margin-top: -10px;
    margin-left: 3px;
  }
}

.textarea {
  > textarea {
    min-height: 185px;
  }
}

.urlInputWrap {
  margin-top: 8px;
}

.urlInput {
  margin-top: 20px;
  margin-bottom: 30px;
  width: 100%;
}

.btn {
  min-width: 265px;
  margin-top: 30px;
}

.border-wrapper {
  border: 1px solid $border;
  border-radius: 8px;
  padding: 30px 60px 60px 60px;
  margin-bottom: 50px;
}

@media screen and (max-width: 970px) {
  .border-wrapper {
    padding: 30px 40px 30px 40px;
  }
}

@media screen and (max-width: 675px) {
  .border-wrapper {
    padding: 30px 20px 30px 20px;
  }
}

@media screen and (max-width: 767px) {
  .btn {
    width: 100%;
  }
}
