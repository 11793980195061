@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  display: grid;
  grid-gap: 30px 20px;
  grid-template-columns: repeat(3, calc(33.3333333% - 13.33333px));
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  width: 100%;

  > label {
    width: 100%;

    > span:last-child {
      width: 100%;
      display: block;
    }
  }
}

.title {
  @include text-nowrap();
  @include text;
  font-weight: 600;
}

.description {
  @include text-nowrap();
  @include desc-1;
  color: $dark-grey;
}

.block {
  max-width: 83%;
}

@media screen and (max-width: 800px) {
  .wrapper {
    grid-template-columns: repeat(2, calc(50% - 10px));
  }
}

@media screen and (max-width: 550px) {
  .wrapper {
    grid-template-columns: repeat(1, 100%);
  }
}
