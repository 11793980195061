@import 'assets/scss/mixins.scss';
@import 'assets/scss/colors.scss';

.customerTemplateSectionSix {
  .listWrapper {
    margin-top: 20px;

    h3 {
      margin-bottom: 10px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    gap: 20px;

    button.remove {
      padding: 10px;
      margin-left: -10px;

      svg {
        @include icon(25px);
      }
    }
  }

  .plusIcon {
    margin-right: 10px;

    rect {
      fill: $green;
    }

    path {
      fill: $white;
    }
  }
}
