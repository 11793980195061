@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  padding-left: 40px;
  width: 50%;

  .name {
    @include h2;
    margin-bottom: 26px;
  }
}

.searchWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  gap: 30px;

  .searchInput {
    min-height: 45px;
    width: 100%;
    max-width: 350px;
  }
}
