@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0px;

  :global {
    .ant-switch-checked {
      background: $turquoise;
    }

    .ant-picker {
      @include border($border, 5px);
      padding: 4px 15px 4px;
      width: 110px;
      box-shadow: none;

      input {
        @include text;

        &::placeholder {
          @include text;
        }
      }
    }

    .ant-picker-status-error {
      @include border($error-color, 5px);
    }
  }

  .dash {
    width: 6px;
    height: 2px;
    background-color: $turquoise;
    margin: 4px 10px 0 10px;
  }
}

:global {
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background: $active-color-light;
    @include text;
    line-height: 32px;
  }

  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell
    .ant-picker-time-panel-cell-inner {
    @include text;
    line-height: 32px;
  }

  .ant-picker-panel-container .ant-picker-footer {
    .ant-picker-ok {
      width: 100%;
    }

    button {
      height: 34px;
      @include text;
      width: 100%;
      color: $white;
      background-color: $turquoise;
      border-radius: 25px;
      border: none;
    }
  }
}

.text {
  @include text;
  font-weight: 600;
  margin: 0px 25px 0px 15px;
  min-width: 140px;
}

.timepicker {
  margin: 0px 30px 0px 0px;
}

.addBreak {
  @include text;
  font-weight: 600;
  margin: 0px 25px 0px 15px;
}

@media screen and (max-width: 1350px) {
  .text {
    margin: 0px 10px 0px 10px;
    min-width: 117px;
  }

  .timepicker {
    margin: 0px 10px 0px 0px;
  }

  .addBreak {
    margin: 0px 10px 0px 10px;
  }
}

@media screen and (max-width: 970px) {
  .wrapper {
    flex-wrap: wrap;

    > div:nth-child(8) {
      width: 110px;
      margin-left: 181px;
      margin-top: 10px;
    }

    > div:nth-child(10) {
      width: 110px;
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 685px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(9, 11.1111%);
    border-bottom: 1px solid $light-grey;
    padding: 20px 0;
    margin: 0;

    > div {
      width: 100% !important;
    }

    .dash {
      width: 6px !important;
    }

    > button:nth-child(1) {
      grid-column: 1;
      width: 50px;
    }

    > p:nth-child(2) {
      grid-column: 2/8;
      min-width: auto;
      margin-left: 17px;
    }

    > div:nth-child(3) {
      grid-column: 1 / 5;
      margin: 15px 0px;
    }

    > div:nth-child(4) {
      grid-column: 5 / 5;
      place-self: center;
    }

    > div:nth-child(5) {
      grid-column: 6 / 10;
      margin-left: 0;
    }

    > button:nth-child(6) {
      width: 50px;
      grid-column: 1;
    }
    > div:nth-child(7) {
      grid-column: 2/8;
      margin-top: 2px;
      margin-left: 17px;
    }

    > div:nth-child(8) {
      grid-column: 1 / 5;
      margin-left: 0;
      margin-top: 15px;
    }

    > div:nth-child(9) {
      grid-column: 5 / 5;
      place-self: center;
    }

    > div:nth-child(10) {
      grid-column: 6 / 10;
      margin-left: 0;
      margin-top: 15px;
    }
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    > button:nth-child(1) {
      grid-column: 1;
      width: auto;
    }

    > button:nth-child(6) {
      width: auto;
      grid-column: 1;
    }
  }
}

@media screen and (max-width: 350px) {
  .wrapper {
    > p:nth-child(2),
    > div:nth-child(7) {
      margin-left: 20px;
    }
  }
}
