@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography-mixins.scss';

.wrapper {
  position: relative;
  width: 100%;

  .error {
    @include desc-1;
    margin-bottom: 0;
    color: $error-color;
  }

  .label {
    @include text;
    margin-bottom: 8px;

    .necessaryLabel {
      @include text;
      color: $dark-grey;
    }
  }

  input {
    @include text;
    @include border($input-border, 5px);
    border: 1px solid $input-border;
    height: 42px;
    padding: 4px 15px;

    &:focus {
      @include border($btn-active-color, 5px);
      border: 1px solid $btn-active-color;
      box-shadow: none;
    }
  }

  .icon {
    @include icon(17px);
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 10;

    svg {
      @include icon(17px);
    }
  }

  button {
    display: none;
  }

  :global {
    .ant-input-group-addon {
      display: none;
    }

    .ant-input:hover {
      border-color: $turquoise;
    }

    .ant-input-search {
      input {
        padding: 4px 32px 4px 15px;
      }
    }

    .ant-input-group > .ant-input:first-child,
    .ant-input-group-addon:first-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      @include border($input-border, 5px);
      box-shadow: none;
    }

    .ant-input-affix-wrapper {
      @include border($input-border, 5px);
      height: 42px;
      padding: 0 21px;
      display: flex;
      align-items: center;
      box-shadow: none;

      &-focused {
        @include border($btn-active-color, 5px);
        border: 1px solid $btn-active-color !important;
        box-shadow: none;
      }

      &:hover,
      &focus {
        box-shadow: none;
      }

      input {
        border: none !important;
        background-color: transparent;
      }

      .ant-input-prefix {
        height: 20px;
        padding-right: 21px;
        margin-right: 15px;
        border-right: 1px solid $light-grey;
      }
    }
  }
}
