@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  padding: 10px 0px;
  display: flex;
}

.upload {
  margin-right: 10px;
  cursor: pointer;
}

.save {
  margin-top: 20px;
  min-width: 140px;
  border-radius: 20px;
  height: 40px;
}

.btn {
  margin-top: 25px;
}

@media screen and (max-width: 1100px) {
  .uploadMoveWrapp {
    margin-right: -20px;

    :global {
      .ant-upload-list-picture-card-container {
        @include icon(170px);
        margin-right: 20px;

        .ant-upload-draggable-list-item {
          @include icon(170px);
        }
      }

      .ant-upload-draggable-list-item {
        &::after {
          @include icon(24px);
          top: -9px;
          right: -9px;
        }
      }

      .ant-upload-select-picture-card {
        @include icon(170px);

        svg {
          @include icon(24px);
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .uploadMoveWrapp {
    margin-right: -20px;

    :global {
      .ant-upload-list-picture-card-container {
        @include icon(148px);
        margin-right: 20px;

        .ant-upload-draggable-list-item {
          @include icon(148px);
        }
      }

      .ant-upload-draggable-list-item {
        &::after {
          @include icon(24px);
          top: -9px;
          right: -9px;
        }
      }

      .ant-upload-select-picture-card {
        @include icon(148px);

        svg {
          @include icon(24px);
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .uploadMoveWrapp {
    margin-right: -10px;

    :global {
      .ant-upload-list-picture-card-container {
        @include icon(64px);
        margin-right: 10px;

        .ant-upload-draggable-list-item {
          @include icon(64px);
        }
      }

      .ant-upload-draggable-list-item {
        &::after {
          @include icon(16px);
          top: -6px;
          right: -6px;
        }
      }

      .ant-upload.ant-upload-select-picture-card {
        @include icon(64px);

        svg {
          @include icon(20px);
        }
      }
    }
  }
  .btn {
    width: 100%;

    button {
      width: 100%;
    }
  }
}
