@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';

.burger {
  @include rotate();
  @include icon(48px);
  @include transition(all, 0.5s, ease-in-out);
  position: relative;
  background: inherit;
  border: 0;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;

  span {
    @include rotate();
    @include transition(all, 0.25s, ease-in-out);
    display: block;
    position: absolute;
    height: 3px;
    max-width: 21px;
    width: 100%;
    background: $light-grey;
    border-radius: 9px;
    left: 14px;
    top: 10px;

    &:nth-child(1) {
      top: 15px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 22px;
    }

    &:nth-child(4) {
      top: 29px;
    }
  }

  &.open {
    background: $turquoise;

    span {
      background: $white;
      width: 20px;

      &:nth-child(1),
      &:nth-child(4) {
        top: 23px;
        width: 0%;
        left: 24px;
      }

      &:nth-child(2) {
        top: 22px;
        @include rotate(45deg);
      }

      &:nth-child(3) {
        top: 22px;
        @include rotate(-45deg);
      }
    }
  }

  @media screen and (max-width: 500px) {
    @include icon(44px);
    padding: 15px;

    span {
      max-width: 17px;
      left: 14px;
      top: 15px;

      &:nth-child(1) {
        top: 15px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 20px;
      }

      &:nth-child(4) {
        top: 25px;
      }
    }

    &.open {
      background: $turquoise;

      span {
        background: $white;
        width: 20px;

        &:nth-child(1),
        &:nth-child(4) {
          top: 20px;
          width: 0%;
          left: 20px;
        }

        &:nth-child(2) {
          top: 20px;
          @include rotate(45deg);
        }

        &:nth-child(3) {
          top: 20px;
          @include rotate(-45deg);
        }
      }
    }
  }
}
