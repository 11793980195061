@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.itemWrapp {
  border-radius: 5px;
  border: 1px solid $border;
  padding: 11px 13px;
  display: flex;
  align-items: center;
  width: 100%;
  transition: 0.6s;
  cursor: pointer;

  .itemIcon {
    width: 13px;
    height: 17px;

    svg:first-child {
      width: 13px;
      height: 17px;
      margin-top: -8px;
    }

    svg:last-child {
      @include icon(17px);
      margin-top: -3px;
    }

    .hide {
      display: none;
    }

    .upload {
      width: 13px;
      height: 17px;
      margin-top: 0px !important;

      path {
        stroke: $turquoise;
      }
    }
  }

  &:hover .hide {
    display: block;
  }

  &:hover .show {
    display: none;
  }

  .itemTitle {
    @include h4;
    @include text-nowrap();
    max-width: 89%;
    color: $black;
    margin-left: 13px;
  }
}
