@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.headerWrapp {
  @include center-between;

  > p {
    width: 22.4%;

    .hint {
      display: none !important;
    }
  }

  .plan {
    @include h3;
    color: $black;
  }

  .status {
    > span {
      display: flex;
      align-items: center;

      svg {
        @include icon(17px);
        margin-right: 5px;
        margin-top: -2px;
      }
    }

    .statusActive {
      color: $turquoise;
    }

    .statusInactive {
      color: $error-color;

      svg path {
        fill: $error-color;
      }
    }
  }

  .price {
    @include text;
    color: $black;
  }

  .date {
    @include text;
    color: $black;
  }

  .action {
    @include text;
    color: $black;

    span {
      display: flex;
      align-items: center;
      width: fit-content;

      svg {
        margin-right: 5px;
        margin-top: -2px;
      }
    }

    .actionSubcribe {
      transition: 0.3s;

      &:hover {
        color: $turquoise;
      }
    }

    .actionCancel {
      transition: 0.4s;

      &:hover {
        color: $error-color;
      }

      svg {
        @include icon(14px);

        path {
          fill: $error-color;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .headerWrapp {
    > p:nth-child(1) {
      width: 20%;
    }

    > p:nth-child(2) {
      width: 20%;
    }

    > p:nth-child(3) {
      width: 20%;
    }

    > p:nth-child(4) {
      width: 28%;
    }

    > p:nth-child(5) {
      width: 20%;
    }
  }
}

@media screen and (max-width: 767px) {
  .headerWrapp {
    flex-direction: column;
    align-items: flex-start;

    > p:nth-child(1),
    > p:nth-child(2),
    > p:nth-child(3),
    > p:nth-child(4),
    > p:nth-child(5) {
      width: 100%;
    }

    > p {
      width: 100%;

      .hint {
        @include desc-2;
        display: block !important;
        color: $grey;
      }
    }

    > p:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
