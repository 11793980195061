@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  width: 100%;

  > span {
    display: block;
    width: 100%;
  }

  :global {
    .ant-upload {
      width: 100%;
      border: none;

      > span {
        padding: 0 !important;
      }
    }

    .ant-upload.ant-upload-drag {
      border: none;
    }

    .ant-upload-list {
      display: grid;
      grid-template-columns: repeat(4, calc(25% - 15px));
      gap: 20px;
      margin-top: 20px;

      &::before {
        display: none;
      }
    }
  }
}

.uploadBtnWrapp {
  @include center;
  width: 100%;
  border: 1px dashed $dark-grey;
  border-radius: 5px;
  background-color: $main-beauty-business-bg-color;
  flex-direction: column;
  padding: 80px 0;

  .drag {
    @include h2;
    margin-top: 20px;
    max-width: 216px;
    text-align: center;
  }

  .browsefile {
    @include h2;
    color: $turquoise;
  }
}

@media screen and (max-width: 1200px) {
  .wrapper {
    :global {
      .ant-upload-list {
        grid-template-columns: repeat(3, calc(33.3333% - 13.33333px));
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .wrapper {
    :global {
      .ant-upload-list {
        grid-template-columns: repeat(2, calc(50% - 10px));
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    :global {
      .ant-upload-list {
        grid-template-columns: repeat(1, 100%);
      }
    }
  }
}
