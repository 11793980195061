@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.photos {
  @include text;
  color: $black;
}

.giveClients {
  @include text;
  color: $dark-grey;
  margin-top: 8px;
  margin-bottom: 28px;
}

@media screen and (max-width: 685px) {
  .uploadMoveWrapp {
    margin-right: -10px;

    :global {
      .ant-upload-list-picture-card-container {
        @include icon(59px);
        margin-right: 10px;

        .ant-upload-draggable-list-item {
          @include icon(59px);
        }
      }

      .ant-upload-draggable-list-item {
        &::after {
          @include icon(16px);
          top: -6px;
          right: -6px;
        }
      }

      .ant-upload.ant-upload-select-picture-card {
        @include icon(59px);

        svg {
          @include icon(21px);
        }
      }
    }
  }
}
