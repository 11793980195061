@import './fonts.scss';
@import './colors.scss';
@import './mixins.scss';
@import './typography.scss';
@import './class-helpers.scss';

html,
body {
  font-family: 'Proxima Nova', sans-serif;
  color: $black;
  min-width: 100%;
  min-height: 100%;
  height: fit-content;
}

a {
  color: $primary-color;
  text-decoration: none;

  &:hover {
    color: darken($primary-color, 10%);
  }
}

p {
  margin: 0;
}

main {
  min-height: 70vh;
}

.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.5);
}

// scroll
::-webkit-scrollbar {
  width: 3px;
  height: 8px;
  background-color: $light-grey;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: $turquoise;
  border-radius: 5px;
}

// spiner
.ant-spin-dot-item {
  background-color: $turquoise;
}
