@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  cursor: pointer;

  .date {
    @include h4;
    color: $grey !important;
  }
}

.avatar {
  display: flex;
  align-items: center;
  width: fit-content;

  > span {
    margin-right: 20px;
  }

  p {
    @include h4;
  }
}

.type {
  @include h4;
  color: $grey;
  margin-left: 20px;
}

.unread.active {
  background-color: $error-color;
  border-radius: 50%;
}

.unread {
  @include icon(12px);
}
