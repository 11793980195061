@import 'assets/scss/colors.scss';

.edit-seo-page {
  .titleWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 60px;

    h1 {
      margin: 0 auto;
      text-transform: capitalize;
    }

    .arrow {
      width: 30px;
      height: 16px;
      cursor: pointer;

      path {
        fill: $turquoise;
      }
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
  }

  .button {
    margin-top: 30px;
  }
}
