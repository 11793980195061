@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.calendarWrapper {
  background: $white;
  border-radius: 6px;
  padding: 10px 0;

  thead {
    margin-bottom: 5px;
  }

  .event {
    @include border($btn-active-color, 25px);
    background: transparent;
    width: 100%;
    height: 39px;
    position: absolute;
    top: -8px !important;
    left: 1px !important;
    z-index: 5;
    margin-left: -1px !important;

    a {
      color: $calendar-event-link-color;
    }
  }

  .dayCell {
    @include text;
    line-height: 18px;
    border: none;

    a {
      color: $black;
    }
  }

  .dayHeader {
    @include text;
    line-height: 18px;
    border: none;

    a {
      color: $calendar-header-text-color;
    }
  }
  :global {
    .fc-day-past {
      .fc-daygrid-day-number {
        div {
          opacity: 0.5;
          cursor: initial;
        }
      }
    }
    .fc-daygrid-day-frame {
      min-height: initial;
    }
  }
}

.dateEvent {
  color: $black;
}

.searchWhenCalendarWrapper {
  @include border($border, 10px);
  background: $white;
  width: auto;
  min-width: 396px;
  height: auto;
  min-height: 352px;
  padding: 30px 40px 40px 40px;

  :global {
    .fc fc-media-screen {
      height: 100%;
    }
    .fc-icon.fc-icon-chevron-left,
    .fc-icon-chevron-right {
      opacity: 0.6;
    }
    .fc-scrollgrid-sync-table {
      margin-top: 10px;
    }
    .fc-scroller-liquid-absolute {
      overflow: initial !important;
    }

    .fc .fc-day-other .fc-daygrid-day-top {
      @include text;
      color: $calendar-header-text-color;
    }

    .fc-day-today .fc-daygrid-day-top div {
      @include h3;
      color: $turquoise;
      line-height: 18px;
    }

    .fc-toolbar {
      .fc-toolbar-chunk {
        .fc-button {
          background: transparent;
          outline: none;
          border: none;
          color: $calendar-header-text-color;

          &:active,
          &:focus,
          &:not(:disabled) {
            background: transparent;
            color: $calendar-header-text-color;
            outline: none;
            box-shadow: none;
          }
        }

        h2 {
          @include h3;
          line-height: 140%;
          color: $calendar-dark-grey-text-color;
        }
      }
    }

    .fc-theme-standard .fc-scrollgrid {
      border: none;
    }

    .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
      position: absolute;
      top: 0;
      min-height: 0;
      left: 0;
      width: 100%;
    }

    .fc .fc-daygrid-day-number {
      z-index: 10;
    }

    .fc .fc-daygrid-day-top {
      align-items: center;
      justify-content: center;
    }

    .fc-theme-standard td,
    .fc-theme-standard th {
      border: none;
    }

    .fc .fc-daygrid-day.fc-day-today {
      background: none;
    }

    .fc-direction-ltr .fc-daygrid-event.fc-event-start,
    .fc-direction-rtl .fc-daygrid-event.fc-event-end {
      margin-left: 0;
    }
  }
}
