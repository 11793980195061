@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  width: 100%;
  .info {
    display: flex;
    margin-bottom: 30px;

    > div {
      width: 47%;
    }

    .infoText {
      @include text;
      color: $dark-grey;
      padding-left: 60px;
      margin-top: 30px;

      svg {
        margin-right: 11px;
        margin-top: -3px;
      }
    }
  }

  .map {
    height: 400px;
  }
}

.mapInput {
  > p {
    @include text;
    position: initial;
    margin-bottom: 8px;
    color: $black;

    span {
      @include text;
      color: $dark-grey;
    }
  }

  input {
    @include border($input-border, 5px);
    @include text;
    width: 100%;
    border: 1px solid $input-border !important;
    height: 42px;
    padding: 4px 35px 4px 15px;

    &:focus {
      @include border($btn-active-color, 5px);
      border: 1px solid $btn-active-color !important;
      box-shadow: none;
    }

    &.map-error-search {
      border: 1px solid $error-color !important;
    }
  }

  .inputWrapp {
    position: relative;
    width: 100%;

    .icon {
      position: absolute;
      top: 8px;
      right: 12px;
    }
  }
}

:global {
  .pac-container {
    &:after {
      display: none;
    }

    .pac-item {
      padding: 4px 10px;
      border: none;

      &:hover {
        background-color: $active-color-light;
      }

      .pac-icon-marker {
        display: none;
      }

      span {
        @include text;
      }

      .pac-matched {
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 970px) {
  .wrapper {
    .info {
      flex-direction: column;

      > div {
        width: 100%;
      }

      .infoText {
        padding-left: 0;
      }
    }
  }
}
