@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography-mixins.scss';

.notifyWrapper {
  max-width: 335px;

  .notifyTitle {
    @include title;
    text-align: center;
    color: $black;
    margin-bottom: 20px;
  }

  button {
    margin: 0 auto;
  }
}
