.adminPages {
  h4 {
    margin-bottom: 60px;
  }

  table {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
}
