@import 'assets/scss/mixins.scss';
@import 'assets/scss/colors.scss';
@import 'assets/scss/typography.scss';

.infoBlock {
  margin-bottom: 30px;

  .switchWrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    h2 {
      @include text-nowrap;
      max-width: 520px;
      margin-bottom: 0;
    }
  }

  p {
    color: $dark-grey;
    max-width: 570px;
    margin-bottom: 30px;
  }

  .input {
    width: 200px;
  }

  .infoText {
    @include title;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
