@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.headerTitle {
  @include h2;
  color: $black;

  span {
    padding-right: 5px;
    color: $turquoise;
  }
}

.categoriesCards {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn {
  margin-bottom: 170px;

  button {
    width: 100%;
  }
}
