@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';
@import 'assets/scss/typography.scss';

.wrapperCalendar {
  display: flex;
  justify-content: center;
  width: 100%;

  :global {
    .fc-customWeek-view {
      > table > tbody > tr > td:last-child {
        .fc-timeline-slots {
          width: 100%;

          > table {
            min-width: 1510px !important;
            width: 1510px !important;
          }
        }
      }

      > table > thead > tr > th:last-child {
        > div > div > div > table {
          min-width: 1510px !important;
          width: 1510px !important;
        }
      }

      table > tbody > tr th:last-child .fc-scroller-harness,
      table > thead > tr th:last-child .fc-scroller-harness {
        margin-left: 44px;
      }

      table > tbody .fc-scrollgrid-sync-table {
        min-width: 1510px !important;
        width: 1510px !important;
      }
    }

    .fc-scrollgrid > thead {
      ::-webkit-scrollbar-thumb {
        background-color: transparent;
      }

      ::-webkit-scrollbar {
        background-color: transparent;
      }
    }
    .calendarWeek {
      .fc-timeline-event .fc-event-main {
        padding: 10px;
      }
    }

    .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid
      > td:nth-child(3)
      > .fc-scroller-harness.fc-scroller-harness-liquid {
      margin-left: 47px;
      border-left: 1px solid $light-grey !important;
    }

    .fc-theme-bootstrap5 td.fc-timeline-slot-lane {
      border-right: 1px solid $light-grey !important;
    }

    .fc-h-event {
      background-color: $light-grey;
    }

    .fc-timeline-header {
      margin-right: 47px !important;
    }

    .fc-timeline-header-row {
      .fc-timeline-slot-frame {
        a {
          @include desc-2;
          color: $black;
        }
      }

      .fc-day-today {
        a {
          color: $turquoise;
        }
      }
    }

    .fc {
      .fc-scrollgrid {
        border-top: none;
        border-left: none;
      }

      .fc-scroller {
        overflow: auto !important;
      }

      .fc-timeline-slots {
        border-bottom: none;
      }
      th,
      tr,
      td {
        border-top: 1px solid transparent;
        border: none !important;
      }

      .fc-resource-timeline-divider {
        width: 0px;
        pointer-events: none;
        border: none;
      }

      .fc-event-main {
        width: 100%;
        height: 100%;
        padding: 10px 0px;
      }

      .fc-timeline-event {
        padding: 0;
        margin: 0;
        height: auto;
        border-radius: 5px;
        border: none;

        &::before {
          display: none;
        }
      }

      .fc-timeline-event-harness {
        padding-bottom: 3px;
      }

      .eventContent__custom {
        @include h4;
        color: $black;

        .eventContent__day {
          @include desc-1;
        }

        .eventContent__week {
          @include desc-1;
          display: flex;
          margin-bottom: 2px;

          > div {
            &.eventContentWeek__break {
              @include desc-2;
              padding: 4px 0;
              text-align: center;
              flex: 1;
              background-color: $white;
              border-radius: 5px;
              text-transform: capitalize;
              word-break: break-word;
            }
          }

          .eventContentWeek__breakWrapp {
            grid-column-start: 1;
          }
        }

        .eventWrapp {
          display: flex;
          flex-direction: column;

          > div {
            display: grid;
          }
        }
      }

      .fc-scrollgrid-section td:first-child .fc-scroller {
        margin-right: -1px;
        overflow: initial !important;
      }
    }

    .calendarDay {
      .fc-timeline-lane-frame {
        &::before {
          position: relative;
          content: '';
          position: absolute;
          background: repeating-linear-gradient(
            -45deg,
            $bg-light-grey 0 15px,
            $bg-grey-3 10px 20px
          );
          width: 100%;
          height: 100px;
          top: 0;
          right: 0;
        }
      }

      .eventContentDay {
        display: flex;
        justify-content: center;

        .eventContent__day {
          margin-left: 15px;
        }

        &.dayNotWrapp {
          margin-right: -60px;
        }
      }

      .fc-timeline-header-row {
        .fc-timeline-slot-frame {
          justify-content: center;
        }
      }
    }

    .calendarWeek {
      .fc-timeline-header-row {
        .fc-timeline-slot-frame {
          justify-content: flex-start;
        }
      }

      .fc-timeline-event {
        margin: 0 2px;
      }
    }

    .fc-resourceTimelineDay-view {
      .fc-timeline-body,
      .fc-timeline-header {
        min-width: 2040px !important;

        > .fc-timeline-slots > table,
        > .fc-scrollgrid-sync-table {
          width: 100% !important;
          min-width: inherit !important;

          tr th {
            border-top: 1px solid transparent;
            border-bottom: none;
          }
        }
      }
    }

    .fc-datagrid-cell.fc-resource {
      height: 100px;
      max-height: 100px;
    }

    .fc-timeline-lane.fc-resource {
      padding-bottom: 1px;
    }

    .fc-timeline-event {
      height: 100px !important;
      max-height: 100px;
    }

    td {
      .fc-scroller-harness.fc-scroller-harness-liquid {
        margin-right: 5px;
      }

      &:first-child .fc-scroller-harness.fc-scroller-harness-liquid {
        width: 247px !important;

        > .fc-scroller.fc-scroller-liquid-absolute
          > .fc-datagrid-body.fc-scrollgrid-sync-table {
          width: 247px !important;
        }
      }
    }

    .fc-scroller-harness.fc-scroller-harness-liquid .fc-scroller {
      margin-right: -12px;
      overflow: auto;
      position: relative;
    }

    .fc-resource-timeline.fc-resource-timeline-flat.fc-timeline.fc-timeline-overlap-enabled.fc-resourceTimelineDay-view.fc-view {
      overflow-y: auto;
      overflow-x: hidden;
      height: 60vh;
    }

    .fc-resource-timeline.fc-resource-timeline-flat.fc-timeline.fc-timeline-overlap-enabled.fc-customWeek-view.fc-view {
      overflow-y: auto;
      overflow-x: hidden;
      height: 60vh;
    }

    .fc-view-harness.fc-view-harness-active {
      height: 60vh !important;
    }
  }
}

.calendar {
  flex-grow: 1;
  margin: 0px 40px 5px;
}

.addTime {
  display: flex;

  justify-content: flex-end;
}

.members {
  margin-top: 70px;
  width: 250px;
  max-height: 650px;
  overflow-y: auto;
  padding-right: 50px;

  .item {
    margin: 24px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    > div {
      max-width: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.wrapperTime {
  display: flex;
  align-items: center;
}

.time {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid $light-grey;

  p {
    font-size: 14px;
    margin: 5px 0px;
    color: $text-grey;
  }
}

.pickerWrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.picker {
  margin-right: 20px;
}

.content {
  display: flex;
  flex-direction: column;
}

.button {
  margin: 5px 0px;
}

.edit {
  cursor: pointer;
}

.select {
  align-items: center;
  display: flex;
  min-width: 700px;

  div {
    margin: 0px 10px;
  }
}

.type {
  min-width: 150px;
}

.copy {
  display: flex;
  justify-content: flex-end;
  margin-right: 35px;
  margin-bottom: 5px;
}

.title {
  margin-left: 25px;
}

.copyModalWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.picker {
  margin-top: 20px;
}

.divider {
  position: relative;
  min-height: 100%;
}

.icon {
  top: 50%;
  position: absolute;
}

.calendar {
  max-width: 300px;
}

.select {
  padding-left: 0px;
  width: 180px;
}

.selectDate {
  width: 140px;
  margin-left: 0px;
}

.weeks {
  height: 150px;

  overflow-y: scroll;
}

.aaa {
  opacity: 0.5;
}

@media screen and (max-width: 1024px) {
  .wrapperCalendar {
    > div {
      margin: 0px 0px 30px 20px;
    }

    :global {
      .fc-customWeek-view {
        .fc-timeline-body {
          min-width: 1510px !important;
        }

        > table > tbody > tr > td:last-child {
          .fc-timeline-slots {
            width: 100%;

            > table {
              min-width: 1510px !important;
              width: 1510px !important;
            }
          }
        }

        > table > thead > tr > th:last-child {
          > div > div > div > table {
            min-width: 1510px !important;
            width: 1510px !important;
          }
        }
      }

      table > thead th:first-child {
        .fc-scroller-harness {
          &::before {
            content: '';
            background-color: $white;
            width: 66px;
            height: 22px;
            position: absolute;
            z-index: 10;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .wrapperCalendar {
    :global {
      .fc-datagrid-cell-frame {
        .fc-datagrid-cell-cushion.fc-scrollgrid-sync-inner {
          padding: 8px 8px 8px 0px;
          width: 140px;
          margin-top: 1px;
        }
      }

      .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid
        > td:nth-child(3)
        > .fc-scroller-harness.fc-scroller-harness-liquid {
        margin-left: -50px;
      }

      table > thead .fc-scroller-harness {
        margin-left: -50px !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .wrapperCalendar {
    :global {
      .fc-datagrid-cell-frame {
        .fc-datagrid-cell-cushion.fc-scrollgrid-sync-inner {
          width: 120px;
        }
      }

      table > thead .fc-scroller-harness {
        margin-left: -79px !important;
      }

      .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid
        > td:nth-child(3)
        > .fc-scroller-harness.fc-scroller-harness-liquid {
        margin-left: -80px;
      }
    }
  }
}
