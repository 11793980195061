@import 'assets/scss/mixins.scss';
@import 'assets/scss/colors.scss';
@import 'assets/scss/typography.scss';

.wrapper {
  background-color: $arrow-bg;
  width: 88px;
  height: 100%;
}

.menu {
  height: 100%;
  background-color: $light-black;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 25;
}

.logo {
  margin-top: 30px;
  margin-bottom: 60px;
}

.menuTitle {
  @include text;
  display: none;
  position: absolute;
  background-color: $light-black;
  left: 78px;
  color: $white;
  padding: 10px 30px;
  white-space: nowrap;
  border-radius: 6px;
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    left: -17px;
    top: calc(50% - 7px);
    border: 7px solid transparent;
    border-right: 10px solid $light-black;
  }
}

.menuItem {
  @include icon(48px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;

  svg {
    @include icon(23px);
  }

  svg path,
  ellipse,
  circle {
    fill: $light-grey;
  }

  &.hideStroke {
    svg path:last-child {
      stroke: $light-grey;
      fill: none;
    }
  }

  &:hover {
    background-color: $btn-hover-color;

    svg path,
    ellipse,
    circle {
      fill: $white;
    }
  }

  &:hover.hideStroke {
    svg path:last-child {
      stroke: $white;
      fill: none;
    }
  }

  &:hover .menuTitle {
    display: block;
  }
}

.menuActive {
  background-color: $btn-active-color;

  svg path,
  ellipse,
  circle {
    fill: $white;
  }
}

.menuActive.hideStroke {
  svg path:last-child {
    stroke: $white;
    fill: none;
  }
}

.logOut {
  @include icon(48px);
  display: flex;
  position: relative;
  @include center;
  border-radius: 5px;
  margin-top: 75px;
  cursor: pointer;
  svg path,
  ellipse,
  circle {
    fill: $light-grey;
  }

  .menuTitle {
    display: none;
  }

  &:hover {
    background-color: $btn-hover-color;

    svg path,
    ellipse,
    circle {
      fill: $white;
    }
  }

  &:hover .menuTitle {
    display: block;
  }
}

.wrapper {
  :global {
    .adminIconSeo {
      &:hover svg path {
        fill: transparent;
        stroke: $white;
      }

      svg {
        path {
          fill: transparent;
        }

        &:hover path {
          fill: transparent;
          stroke: $white;
        }
      }
    }
  }

  .menuActive {
    &:global {
      &.adminIconSeo {
        svg path {
          fill: transparent;
          stroke: $white;
        }
      }
    }
  }

  .menuNotification {
    &::before {
      content: '';
      @include icon(20px);
      position: absolute;
      background-image: url('../../assets/images/common/alert-circle.svg');
      right: 5px;
      top: 5px;
      z-index: 10;
    }
  }

  .menuNotificationCount {
    @include icon(18px);
    @include center;
    position: absolute;
    border-radius: 50%;
    background-color: $error-color;
    right: 5px;
    top: 5px;
    z-index: 10;
    color: $white;
    font-size: 13px;
  }
}

@media screen and (max-width: 1024px) {
  .wrapper {
    width: 100%;
    max-height: calc(100vh - 74px);
    overflow-y: auto;
    margin-top: -2px;
  }

  .menu {
    padding: 16px 0px 0px 20px;
    align-items: flex-start;
  }

  .logo {
    display: none;
  }

  .logOut {
    display: none;
  }

  .menuTitle {
    @include text;
    display: block;
    padding-left: 0;

    &::before {
      display: none;
    }
  }

  .menuItem {
    @include icon(48px);
  }
}

@media screen and (max-width: 500px) {
  .menu {
    padding: 16px 0px 0px 15px;
    align-items: flex-start;
  }

  .menuItem {
    @include icon(44px);
  }
}
