@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';

.settingsPage {
  h4 {
    margin-bottom: 40px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    padding: 30px;
    border: 1px solid $light-grey;

    .list {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .listTitle {
        display: flex;
        align-items: center;
        gap: 20px;

        .plusIcon {
          @include icon(46px);
          cursor: pointer;

          rect {
            fill: $black;
          }

          path {
            fill: $white;
          }
        }
      }
    }

    .saveButton {
      margin-top: 40px;
    }
  }
}
