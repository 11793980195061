@import 'assets/scss/colors.scss';
@import 'assets/scss/mixins.scss';

.wrapper {
  display: flex;
  min-width: 100%;
  :global {
    .ant-image {
      display: flex;
      height: 100%;
      border: 1px solid $bg-grey-2;
      border-radius: 50%;
      overflow: hidden;
      img {
        object-fit: cover;
      }
    }
    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.title {
  font-size: 26px;
  margin-bottom: 50px;
}
