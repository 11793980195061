@import 'assets/scss/colors.scss';
@import 'assets/scss/typography.scss';

.tabletRow {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  border-bottom: 1px solid $light-grey;

  .rowWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      @include desc-2;
      color: $grey;
    }

    .item {
      @include desc-1;
    }

    .answer {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 20px 0;

    .rowWrapper {
      gap: 5px;
    }
  }
}
